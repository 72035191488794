import React, { Fragment, Component } from 'react';
import './team.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Team extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>AV TEAM</title>
          <meta
            name="description"
            content="
PARTNERS: MOHAN KUMAR, NISHANT RAO, MIKE MULICA, GEORGE THANGADURAI, SUDHANSHU, 
"
          />

          <meta
            name="keywords"
            content="
US - Business Development Team
US - Business Operations Team
GTM Expert COE
Security & Analytics COE
Product & Tech COE
Founding partners, 
track records of scaling companies
building strong teams
building great global businesses
Norwest Venture Partners 
Motorola, Corporate Vice-President, Kellogg, Global Head of Strategy, LinkedIn, Freshworks, COO, Epicenter, Massachusetts Institute of Technology, 40- under-40 executive, key strategist, farsighted investor, board of director, Phone.com, Syniverse, Sierra Wireless, Openwave, RealNetworks, FusionOne, Synchronoss, Actility, Unwired Planet Inc, Airmap, AlefEdge, Apponomic; AVP based out of San Fransisco, multi-functional leadership experience, Intel Corporation, senior management roles, Intel’s Achievement Award, Borqs Technologies, Mobiliya Technologies, Quest Global, trusted advisor, patents,  Investment banker at Lincoln International, Nomura, Edelweiss,

"
          />
        </Helmet>
        <div className="background_image_team">
          <div
            className="row home_section"
            id="home"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div
              className="col-sm-12 banner_text team_banner_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h1 className="ethos_banner_heading">TEAM</h1>
              <div className="home-content d-inline-flex">
                <div className="sub_section team_sub_section">
                  <p>
                    We see ourselves as trusted partners who have been invited
                    to enable our founders’ success. So, with humility &
                    purpose, we work to first earn the trust of our leadership
                    teams and ultimately become confidants & reliable
                    consiglieres. We value this trust put in us & having been
                    CEOs ourselves, with successful track records of scaling
                    companies, each of us brings a passion for the 4Es that go
                    into building strong teams & great global businesses.{' '}
                  </p>
                  <p>
                    <strong>
                      Entrepreneurial DNA • Expansion Expertise • Executive
                      Networks • Empathy
                    </strong>
                  </p>
                  <p>
                    Come, explore the many “avataars" of each of our team
                    members…
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-sm-12 text-center arrow bounce"
            style={{ position: 'absolute', bottom: '20px' }}
          >
            <a href="#ethos">
              <img
                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/arrow.svg"
                style={{ width: '46px', height: '21px' }}
                alt="arrow"
              />
            </a>
          </div>
        </div>

        <div
          className="ethos"
          id="ethos"
          data-aos="fade-up"
          data-aos-duration="100"
          style={{ paddingBottom: '2rem' }}
        >
          <div className="ethos-framework-para team_framework_para">
            <center>
              <p>TEAM</p>
            </center>
          </div>
          <div className="">
            <div className="mt-4" data-aos="fade-up" data-aos-duration="1500">
              <div className="row justify-content-center">
                <div
                  class="item col-md-3 col-sm-12 col-xs-12 "
                  data-toggle="modal"
                  data-target="#AkshitaModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Akshita_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        AKSHITA SHAH
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Associate
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </div>
                <div
                  class="item col-md-3 col-sm-12 col-xs-12 "
                  data-toggle="modal"
                  data-target="#AmitModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Amit_Kumar_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        Amit Kumar
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Head - Finance COE
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </div>
                <div
                  class="item col-md-3 col-sm-12 col-xs-12 "
                  data-toggle="modal"
                  data-target="#AnirudhModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Anirudh_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        Anirudh Singh
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Partner
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </div>
              </div>
              <div className="row d-flex flex-wrap justify-content-center mt-5">
                <div
                  class="item col-md-3 col-sm-12 col-xs-12"
                  data-toggle="modal"
                  data-target="#DeepakModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Deepak+Rathi_image_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        DEEPAK RATHI
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Analyst
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </div>
                <div
                  class="item col-md-3 col-sm-12 col-xs-12 "
                  data-toggle="modal"
                  data-target="#DinkyModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Dinky_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        DINKY SANGHVI
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          VP Finance
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </div>
                <div
                  class="item col-md-3 col-sm-12 col-xs-12 "
                  data-toggle="modal"
                  data-target="#DiwakarModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Diwakar_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        DIWAKAR ANAND
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Senior Associate
                        </span>
                      </div>
                    </div>
                  </div>{' '}
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#ganeshModal"
                    >
                      <div className="parent_team_img_container">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Ganesh_Nayak_Avataar+-78_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                          // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                          // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            GANESH NAYAK
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Fundraising and Capital Markets
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#georgeModal"
                    >
                      <div className="parent_team_img_container">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/George+_Cropped2.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george_seperaate_component">
                          <div className="text">
                            GEORGE THANGADURAI
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Operating Partner - Fund Management & Compliance
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="item col-md-3 col-sm-12 col-xs-12 "
                  data-toggle="modal"
                  data-target="#krishModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Krishen_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                      // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/george_color.jpg'"
                      // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/george1-black_white.png'"
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        KRISH KUPATHIL
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Venture Partner
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex flex-wrap justify-content-center mt-md-5">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#mikeModal"
                    >
                      <div className="parent_team_img_container parent_team_img_container_height">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mike-mulica_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            MIKE MULICA
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Operating Partner
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#mohanModal"
                    >
                      <div className="parent_team_img_container">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mohan_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay overlay_text_width">
                          <div className="text">
                            MOHAN KUMAR <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Managing Partner
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#nishantModal"
                    >
                      <div className="parent_team_img_container parent_team_img_container_height">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Nishant_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay">
                          <div className="text">
                            NISHANT RAO
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Founding Partner
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex flex-wrap justify-content-center mt-md-5">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#shobhitModal"
                    >
                      <div className="parent_team_img_container">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Shobhit_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                          // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                          // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            SHOBHIT GUPTA
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Principal
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#spurthiModal"
                    >
                      <div className="parent_team_img_container">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Spurthi_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                          // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                          // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            SPURTHI NAIDU
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              People & Operation Manager
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  <div className="text-center card_wrapper">
                    <div
                      className=""
                      data-toggle="modal"
                      data-target="#subhroModal"
                    >
                      <div className="parent_team_img_container">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Subhro_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                          // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                          // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            SUBHRO SENGUPTA
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Associate
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
										<center>
											<div className="text-center card_wrapper">
												<div
													className=""
													data-toggle="modal"
													data-target="#mikeModal"
												>
													<div className="parent_team_img_container parent_team_img_container_height">
														<img
															src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg"
															className="card-img-top"
															alt="..."
														/>
														<div className="overlay">
															<div className="text">
																MIKE MULICA
																<br />
																<span
																	style={{
																		fontWeight:
																			"400",
																	}}
																>
																	Operations
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</center>
									</div>
								</div>*/}
            </div>
          </div>
        </div>

        {/* <div className="ethos-framework-para team_framework_para mt-5">
					<center>
						<p className="mb-0">Venture Partners</p>
					</center>
				</div>
				<div className="row">
					<div className="container">
						<div
							className="people_flex_container mt-1"
							data-aos="fade-up"
							data-aos-duration="1500"
							style={{ paddingBottom: "2rem" }}
						>
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
									<div className="text-center card_wrapper">
										<div
											className=""
											data-toggle="modal"
											data-target="#krishModal"
										>
											<div className="parent_team_img_container parent_team_img_container_height">
												<img
													src="https://s3.ap-south-1.amazonaws.com/avataar.vc/krish_crop_img.png"
													className="card-img-top"
													alt="..."
												/>
												<div className="overlay">
													<div className="text">
														Krish Kupathil
														<br />
														<span
															style={{
																fontWeight:
																	"400",
															}}
														>
															Tech & Product
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}

        {/* <div className="ethos-framework-para mt-4 team_framework_para">
					<center>
						<p>TEAMS WE ARE BUILDING NOW</p>
					</center>
				</div>
				<div className="row">
					<div className="container">
						<div className="people_flex_container mt-4">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
									<div
										className="team_rectangle"
										data-toggle="modal"
										data-target="#usbdModal"
									>
										<div className="ethos-title">
											US-BD <br /> Team
										</div>
										<div className="ethos-content">
											KNOW MORE
											<span
												style={{
													paddingLeft: "20px",
													fontWeight: "600",
												}}
											>
												&#62;
											</span>
										</div>
									</div>
									<div
										class="modal fade"
										id="usbdModal"
										tabindex="-1"
										role="dialog"
										aria-labelledby="exampleModalLabel"
										aria-hidden="true"
									>
										<div
											class="modal-dialog modal-lg team_modal_bg"
											role="document"
										>
											<div class="modal-content team_modal_content">
												<div className="row">
													<div className="container">
														<div class="modal-header">
															<p
																class="modal-title"
																id="exampleModalLabel"
															>
																US - Business
																Development Team
															</p>

															<i
																className="lni lni-close"
																data-dismiss="modal"
															/>
														</div>
													</div>
												</div>
												<div class="modal-body">
													<div className="team-modal-para-detail">
														<p>
															The United States is
															a market like no
															other. Succeeding
															here is crucial to
															any company’s global
															scale. Yet, like
															many other markets,
															the ability to crack
															it lies in having
															strong prior
															relationships to
															open up the right
															doors at the right
															levels. Our kickass
															BD team leaders each
															come with a proven
															past track record of
															having led large
															businesses at tech
															companies.
														</p>
														<p>
															Their focus is
															three-fold:
															<ul>
																<li>
																	Foster
																	strong
																	relationships
																	as thought
																	partners to
																	CXOs in our
																	priority
																	industries;
																	over time
																	this also
																	helps
																	cultivate
																	the right
																	strategic
																	exit
																	dialogues
																</li>
																<li>
																	Generate
																	qualified
																	pipeline
																	every
																	quarter for
																	our
																	portfolio
																	companies
																</li>
																<li>
																	Leverage
																	their past
																	learnings to
																	coach
																	portfolio
																	teams &
																	maximize
																	closed ARR
																</li>
																<li>
																	Once we have
																	established
																	our US
																	presence, we
																	plan to have
																	similar
																	local BD
																	leadership
																	in other
																	core
																	international
																	markets
																	across
																	Europe, APAC
																	& LATAM.
																</li>
															</ul>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
									<div
										className="team_rectangle"
										data-toggle="modal"
										data-target="#bizopModal"
									>
										<div className="ethos-title">
											Biz Ops <br /> Team
										</div>
										<div className="ethos-content">
											KNOW MORE
											<span
												style={{
													paddingLeft: "20px",
													fontWeight: "600",
												}}
											>
												&#62;
											</span>
										</div>
									</div>
									<div
										className="modal fade"
										id="bizopModal"
										tabindex="-1"
										role="dialog"
										aria-labelledby="exampleModalLabel"
										aria-hidden="true"
									>
										<div
											class="modal-dialog modal-lg team_modal_bg"
											role="document"
										>
											<div class="modal-content team_modal_content">
												<div className="row">
													<div className="container">
														<div class="modal-header">
															<p
																class="modal-title"
																id="exampleModalLabel"
															>
																US - Business
																Operations Team
															</p>
															<i
																className="lni lni-close"
																data-dismiss="modal"
															/>
														</div>
													</div>
												</div>
												<div class="modal-body">
													<div className="team-modal-para-detail">
														<p>
															In today’s day &
															age, having the
															right data & mining
															the right insights
															from it are crucial
															to the successful
															scale of any
															business. Yet, most
															companies get
															consumed by the
															day-to-day,
															week-to-week &
															quarter-to-quarter
															priorities (and we
															agree that one can’t
															take the eye off
															those).
														</p>
														<p>
															Our BizOps team,
															with dedicated team-
															members for each
															portfolio company,
															works to re-build &
															strengthen your
															company’s data
															foundations. And
															then leveraging that
															data to unlock
															timely growth
															insights – whether
															it is to answer top
															of mind strategic
															questions for
															portfolio CXOs –or-
															to look out on a
															farther time horizon
															to proactively
															identify future
															bottlenecks or
															near-term growth
															opportunities.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
									<div
										className="team_rectangle"
										data-toggle="modal"
										data-target="#gtmModal"
									>
										<div className="ethos-title">
											GTM <br />
											Expert <br />
											COE
										</div>
										<div className="ethos-content">
											KNOW MORE
											<span
												style={{
													paddingLeft: "20px",
													fontWeight: "600",
												}}
											>
												&#62;
											</span>
										</div>
									</div>
									<div
										className="modal fade"
										id="gtmModal"
										tabindex="-1"
										role="dialog"
										aria-labelledby="exampleModalLabel"
										aria-hidden="true"
									>
										<div
											class="modal-dialog modal-lg team_modal_bg"
											role="document"
										>
											<div class="modal-content team_modal_content">
												<div className="row">
													<div className="container">
														<div class="modal-header">
															<p
																class="modal-title"
																id="exampleModalLabel"
															>
																GTM Expert COE
															</p>
															<i
																className="lni lni-close"
																data-dismiss="modal"
															/>
														</div>
													</div>
												</div>
												<div class="modal-body">
													<div className="team-modal-para-detail">
														<p>
															With each market &
															stage of growth
															requiring different
															growth-hacking
															techniques, we aim
															to provide hands-on
															services to help our
															portfolio companies
															efficiently scale.
														</p>
														<p>
															These team members
															will have deep
															expertise in sales
															(both enterprise &
															inside-selling) and
															marketing (both
															outbound & inbound).
															They will translate
															prior hands-on
															project experience
															in areas like Sales
															Effectiveness, Sales
															Org design & ABM,
															into codified
															playbooks that they
															will use to guide
															your journey.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
									<div
										className="team_rectangle"
										data-toggle="modal"
										data-target="#securityModal"
									>
										<div className="ethos-title">
											Security & Analytics COE
										</div>
										<div className="ethos-content">
											KNOW MORE
											<span
												style={{
													paddingLeft: "20px",
													fontWeight: "600",
												}}
											>
												&#62;
											</span>
										</div>
									</div>
									<div
										className="modal fade"
										id="securityModal"
										tabindex="-1"
										role="dialog"
										aria-labelledby="exampleModalLabel"
										aria-hidden="true"
									>
										<div
											class="modal-dialog modal-lg team_modal_bg"
											role="document"
										>
											<div class="modal-content team_modal_content">
												<div className="row">
													<div className="container">
														<div class="modal-header">
															<p
																class="modal-title"
																id="exampleModalLabel"
															>
																Security &
																Analytics COE
															</p>
															<i
																className="lni lni-close"
																data-dismiss="modal"
															/>
														</div>
													</div>
												</div>
												<div class="modal-body">
													<div className="team-modal-para-detail">
														<p>
															Expert team members
															will help you with
															tricky yet important
															items like GDPR
															compliance, data
															warehouse design &
															BI/analytics
															support.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
									<div
										className="team_rectangle"
										data-toggle="modal"
										data-target="#productModal"
									>
										<div className="ethos-title">
											Product & Tech COE
										</div>
										<div
											className="ethos-content"
											style={{ paddingTop: "40px" }}
										>
											KNOW MORE
											<span
												style={{
													paddingLeft: "20px",
													fontWeight: "600",
												}}
											>
												&#62;
											</span>
										</div>
									</div>
									<div
										className="modal fade"
										id="productModal"
										tabindex="-1"
										role="dialog"
										aria-labelledby="exampleModalLabel"
										aria-hidden="true"
									>
										<div
											class="modal-dialog modal-lg team_modal_bg"
											role="document"
										>
											<div class="modal-content team_modal_content">
												<div className="row">
													<div className="container">
														<div class="modal-header">
															<p
																class="modal-title"
																id="exampleModalLabel"
															>
																Product & Tech
																COE
															</p>
															<i
																className="lni lni-close"
																data-dismiss="modal"
															/>
														</div>
													</div>
												</div>
												<div class="modal-body">
													<div className="team-modal-para-detail">
														<p>
															Consult with tech
															experts that have
															themselves grappled
															with building &
															scaling complex
															enterprise products
															in the past.
														</p>
														<p>
															Eventually, we might
															even productize some
															of these services
															for faster execution
															for any new Avataar
															portfolio company.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}

        <div className="row mb-5">
          <div className="container">
            <div className="about_btn_center team_component_btn">
              <center>
                <Link to="/media">
                  <button
                    type="button"
                    className="btn btn-outline-primary team_avataar_media_end_btn_spec"
                  >
                    <div className="flex_btn">
                      <div>AVATAAR IN THE MEDIA</div>
                      <div>
                        <span>
                          {' '}
                          <i className="fas fa-angle-right" />
                        </span>
                      </div>
                    </div>
                  </button>
                </Link>
              </center>
            </div>
          </div>
        </div>

        {/******NISHANT MODAL********/}
        <div className="modal" id="nishantModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Nishant_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Nishant Rao</div>
                        <div className="stakeholder-position">
                          <b>
                            Investor | Proven Scale-up Entrepreneur | Sales &
                            GTM expert | Strategy & BizOps
                          </b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a href="" target="_blank">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Mail_modal.svg"
                            width="25"
                            height="25"
                          />
                        </a>
                        <a href="https://twitter.com/nishrao/" target="_blank">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/twitter_logo.svg"
                            width="25"
                            height="25"
                            alt="avatar_twitter"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/nishantrao/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="avataar_linkedin"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Nishant Rao brings expertise in B2B technology & SaaS
                        sales and marketing with a repeat track record of
                        delivering value & globally scaling successful companies
                        in consumer internet & enterprise software with multiple
                        stints of growth past $100M+.{' '}
                      </p>
                      <p>
                        He has played many avataars across a multitude of
                        leadership roles from running global growth-stage SaaS
                        companies achieving unicorn status (Global Head of
                        Strategy, LinkedIn HQ then MD, LinkedIn-India & SE Asia
                        + Global COO @ Freshworks) to being an entrepreneur
                        (helped co-found Epicenter, India’s 1st voice
                        call-centre), a C-level strategy advisor (McKinsey) & an
                        engineer/product manager (Ariba & Microsoft).
                      </p>
                      <p>
                        He has an MBA from the Massachusetts Institute of
                        Technology and a Computer Science degree from UT,
                        Austin. He has been recognized as 40- under-40 executive
                        by the likes of Economic Times & Fortune for impact
                        created
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://capillarytech.com/" target="_blank">
                        Capillary
                      </a>{' '}
                      |{' '}
                      <a href="https://www.crmnext.com/" target="_blank">
                        CRMNext
                      </a>{' '}
                      |{' '}
                      <a href="https://www.zenoti.com/" target="_blank">
                        Zenoti
                      </a>{' '}
                      |{' '}
                      <a href="https://rategain.com/" target="_blank">
                        RateGain
                      </a>{' '}
                      |{' '}
                      <a href="https://www.sensehq.com/" target="_blank">
                        Sense
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END NISHANT MODAL*******/}

        {/**********MOHAN MODAL*********/}
        <div className="modal" id="mohanModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mohan_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Mohan Kumar</div>
                        <div className="stakeholder-position">
                          <b>Investor | Product Maven | Team Builder</b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://twitter.com/kumarmohanv"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/twitter_logo.svg"
                            width="25"
                            height="25"
                            alt="twitter_logo"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/vmohankumar"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Mohan Kumar was the Managing Partner at Norwest Venture
                        Partners (NVP) and has led investment rounds in some of
                        the most successful Enterprise and SAAS startup stories
                        in India.
                      </p>
                      <p>
                        Before his avataar as a VC, he spent two decades in
                        leadership & operating roles including as Corporate
                        Vice-President of Motorola, responsible for leading
                        their Mobile Products division & scaling their
                        product/design centres across Asia.
                      </p>
                      <p>
                        He is an IIT undergrad alum with Exec-MBA from Kellogg.
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      {' '}
                      <a href="https://www.elastic.run/" target="_blank">
                        Elastic Run
                      </a>{' '}
                      |{' '}
                      <a href="https://algonomy.com/" target="_blank">
                        Algonomy
                      </a>{' '}
                      |{' '}
                      <a href="https://healsoftware.ai/" target="_blank">
                        Heal Software
                      </a>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**********END MOHAN MODAL**********/}

        {/*********MIKE MODAL***********/}
        <div className="modal" id="mikeModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mike-mulica_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Mike Mulica</div>
                        <div className="stakeholder-position">
                          <b>Operating Partner</b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://www.linkedin.com/in/mikemulica/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Mike Mulica is an advisor for AVP, based out of Chicago,
                        US. A key strategist & farsighted investor in the areas
                        of mobile communications, the internet, and globally
                        scaled vertical solutions that Avataar has been able to
                        bank upon. Mike is a mobile and internet industry
                        veteran, playing a foundational role in pioneering the
                        Mobile Internet and has led many highly successful
                        companies and product introductions across the globe.
                      </p>
                      <p>
                        Mike spends his time as an active investor, board
                        director and advisor to private companies and public
                        companies. Mike’s stewardship & governance of companies
                        in emerging technologies has ushered in new large global
                        markets that have changed the way the world works.
                        Mike’s advisory companies include Phone.com, Syniverse,
                        Sierra Wireless, Openwave, RealNetworks, FusionOne,
                        Synchronoss, Actility, Unwired Planet Inc, Airmap,
                        AlefEdge and most recently Heal Software.
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://healsoftware.ai/" target="_blank">
                        Heal Software
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END MIKE MODAL*********/}

        {/*************GRORGE MODAL*********/}
        <div className="modal" id="georgeModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/George+_Cropped2.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">
                          George Thangadurai
                        </div>
                        <div className="stakeholder-position">
                          <b>
                            Operating Partner - Fund Management & Compliance
                          </b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://twitter.com/George_Thangad"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/twitter_logo.svg"
                            width="25"
                            height="25"
                            alt="twitter_logo"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/george-thangadurai-9034ba3/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        George drives business development functions for AVP
                        based out of San Francisco and helps portfolio companies
                        build deep CXO connects. He is a seasoned technology
                        executive with multi-functional leadership experience,
                        startup investments and helping grow businesses.
                      </p>
                      <p>
                        Earlier George spent more than two decades in Intel
                        Corporation in senior management roles that included GM
                        of strategy & product management enabling revenue growth
                        from $7B to $20B+, member of the Intel Capital
                        Investments Committee and GM of Services Incubation with
                        3 successful launches. He was awarded the prestigious
                        Intel’s Achievement Award for contributions to business
                        growth.
                      </p>
                      <p>
                        He leads global business for Borqs Technologies, a
                        leading IoT company, with a key role during its Nasdaq
                        IPO process and growing global revenues. George was also
                        CSO/CMO and Board Advisor for Mobiliya Technologies,
                        which was acquired by Quest Global. He has held multiple
                        board and advisory roles and is a trusted advisor to
                        many CxOs of emerging companies.
                      </p>
                      <p>
                        George has 6 USA patents, 2 IEEE publications and
                        interviewed in multiple technology magazines.
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://healsoftware.ai/" target="_blank">
                        Heal Software
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************END GEORGE MODAL******/}
        {/****************SUDHANSHU MODAL********/}
        <div className="modal" id="sudhanshuModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/george_color+Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Sudhanshu Bhasin</div>
                        <div className="stakeholder-position">
                          <b>
                            Business Analysis & Modeling Guru | BizOps |
                            Investment Banking
                          </b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://www.linkedin.com/in/sudhanshubhasin/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Sudhanshu brings in deep domain knowledge of the B2B
                        technology and SaaS space. As an ex-investment banker at
                        Lincoln International, he worked extensively with
                        multiple technology companies.
                      </p>
                      <p>
                        In his last role, Sudhanshu helped raise growth capital
                        for an HR-tech SaaS company and a global,
                        Enterprise-focused Logistics SaaS company. Prior to
                        that, he held investment banking roles at Nomura and
                        Edelweiss. Sudhanshu also has extensive capital markets
                        experience having executed several IPOs in his previous
                        roles.
                      </p>
                      <p>
                        Sudhanshu holds an MBA from NMIMS ( Mumbai) and a B.E.
                        from Delhi College of Engineering (Delhi University). He
                        is also CFA Level-III cleared.{' '}
                      </p>
                    </div>
                    {/*<div className="heading">
											Current Companies
										</div>

										<div className="current_companies">
											<a
												href="https://appnomic.com/"
												target="_blank"
											>
												Appnomic Inc
											</a>
										</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***************END SUDHANSHU MODAL***********/}
        {/*********shobhit MODAL***********/}
        <div className="modal" id="shobhitModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Shobhit_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Shobhit Gupta</div>
                        <div className="stakeholder-position">
                          <b>Growth Platform Lead | GTM expert</b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://www.linkedin.com/in/shobhitgupta23/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Shobhit leads the growth platform team of Avataar and
                        partners with the management teams of the portfolio
                        companies on growth topics. He brings a mix of
                        management consulting (McKinsey, PwC), SaaS start-up,
                        and large tech industry (Dell) experience. He
                        specializes in go-to-market planning and execution
                        including identifying attractive market segments with
                        clear right to win and formulating sales pathways to
                        effectively reach them. Collaborating with and learning
                        from inspiring entrepreneurs and building on India's
                        distinctive advantage to build tech products for global
                        markets excites him.
                      </p>
                      <p>
                        He holds a Master of Science from Texas A&M University.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END shobhit MODAL*********/}
        {/*********spurthi MODAL***********/}
        <div className="modal" id="spurthiModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Spurthi_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Spurthi Naidu </div>
                        <div className="stakeholder-position">
                          <b>People & Operation Manager</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Spurthi is part of our team as a Operations associate
                        where she actively engages with the portfolio companies
                        in their growth alongside the fund venture activities.
                        Prior to this she was at Let’s Accelerate (Let’s
                        Venture) and worked with the team in designing &
                        managing startup innovation programs. Her early career
                        started with Kalaari Capital Advisors as a HR executive
                        who led Kstart's first Fellowship program.
                      </p>
                      <p>
                        Spurthi is a Gold Medalist from Bangalore University in
                        her MBA - HR dept.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="ganeshModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Ganesh_Nayak_Avataar+-78_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Ganesh Nayak </div>
                        <div className="stakeholder-position">
                          <b>Fundraising and Capital Markets</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Ganesh drives Fundraising & Capital Markets at Avataar.
                        He comes with deep understanding of the venture
                        ecosystem, and relationship with India's top
                        entrepreneurs, VCs, and global institutional investors.
                      </p>
                      <p>
                        Ganesh has been a founding member of LetsVenture -
                        India’s premier private market platform with over 750
                        direct startup investments. Some of his marquee deals
                        include PolicyBazaar, Mobikwik, MCaffeine among others.
                        He has also raised capital for global VC funds like
                        Pioneer Fund, MFV Partners, IndiaQuotient among others.
                        He has served on the LP Advisory Board at Pioneer Fund
                        (top decile VC).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="subhroModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Subhro_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Subhro Sengupta </div>
                        <div className="stakeholder-position">
                          <b>B2B Ecommerce | Business Strategy | Investments</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Subhro comes with experience in B2B businesses, having
                        worked with the founding team at B2B unicorn udaan.
                      </p>
                      <p>
                        At udaan, he was a part of the Corporate Development
                        team, working on strategy for new businesses and
                        managing strategic investments and M&A. His expertise
                        lies in B2B marketplaces, SME credit, SME SaaS,
                        logistics and all things targeted towards fragmented B2B
                        markets in India.
                      </p>
                      <p>
                        Prior to udaan, Subhro was a part of the early stage
                        investment team at Kalaari Capital in Bengaluru, where
                        he looked at startups in B2B Ecommerce, Content,
                        Healthtech and Fintech.
                      </p>
                      <p>
                        Subhro is a National Law University alumnus, with a
                        degree in Law and Economics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="krishModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Krishen_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Krish Kupathil </div>
                        <div className="stakeholder-position">
                          <b>Venture Partner</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        A serial entrepreneur and a veteran angel investor,
                        Krish has joined AVP as an advisor based out of US.
                        Krish brings in more than 25 years of experience in
                        building and growing companies and new markets. As an
                        advisor, Krish will be available to help Avataar and its
                        portfolio companies realize transformative solutions by
                        connecting technology with real-world business
                        challenges and opportunities.
                      </p>
                      <p>
                        Drawing from his passion of building, investing and
                        incubating start-ups, Krish has previously been
                        responsible for executing a host of start-ups, business
                        turnarounds, implementation of high-growth models,
                        capital raising and successful exits. He has been
                        focused on businesses looking to leverage innovative
                        products, solutions and business models and creatively
                        use technology to commercialize novel ideas.
                      </p>
                      <p>
                        His insights and an innovative take on commercialization
                        of digital technologies like IoT, AI, AR will help bring
                        value to AVP’s portfolio.
                      </p>
                      <p>
                        Apart from AVP, Krish has formerly been associated with
                        diverse companies like global enterprise mobility
                        company Mobiliya (now acquired by QuEST Global), ABI
                        Health and ImaginXP as a CEO, investor &amp; mentor,
                        respectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*********Diwakar MODAL***********/}
        <div className="modal" id="DiwakarModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Diwakar_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Diwakar Anand </div>
                        <div className="stakeholder-position">
                          <b>Senior Associate</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Diwakar comes with experience in investing across both
                        private and public markets, having worked with Eight
                        Roads and Goldman Sachs.
                      </p>
                      <p>
                        At Eight Roads, he was part of the Tech investments team
                        – driving investments across Enterprise tech, Fintech,
                        B2B Tech and Consumer segments. He believes SaaS will
                        continue to have strong growth momentum, with product /
                        community led growth strategies leading the way.
                      </p>
                      <p>
                        Prior to Eight Roads, Diwakar worked as an equity
                        analyst at Goldman Sachs and as a consultant at McKinsey
                        & Company. At McKinsey, he advised PE firms and
                        conglomerates on their strategy and investments.
                      </p>
                      <p>
                        Diwakar completed his Masters in Finance from Jamnalal
                        Bajaj Institute of Management studies, University of
                        Mumbai and BFIA from Shaheed Sukhdev College of Business
                        Studies, Delhi University.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Diwakar MODAL*********/}

        {/*********Akshita MODAL***********/}
        <div className="modal" id="AkshitaModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Akshita_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Akshita Shah</div>
                        <div className="stakeholder-position">
                          <b>Associate</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Akshita Shah started her career in the IPO team of L&T
                        infotech where she managed compliance and investor
                        relations. She later joined Acuris as a M&A analyst.
                        Here she tracked the North American and European markets
                        for value creation opportunities and published sectoral
                        reports. Post this, Akshita joined Peak Ventures as an
                        investment analyst. She looked after EdTech, AgriTech
                        and Electric Mobility. She also briefly interned in the
                        strategy team of Dehaat as a Pre-MBA analyst.
                      </p>
                      <p>
                        Akshita holds a BAF from KJ Somaiya and an MBA from the
                        Indian School of Business. She is a CS and a CFA.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Akshita MODAL*********/}

        {/*********Deepak MODAL***********/}
        <div className="modal" id="DeepakModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Deepak+Rathi_image_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">DEEPAK RATHI</div>
                        <div className="stakeholder-position">
                          <b>Analyst</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Deepak joined the firm in 2023, and currently serves as
                        Analyst in the Investment and BizOps team, assisting in
                        the investment due diligence process and operational
                        value-add initiatives. Prior to joining the firm, Deepak
                        was an equity research analyst at Spark Capital in their
                        institutional equities desk focusing on Cement and Real
                        estate sector. Prior to Spark Capital, Deepak worked
                        with VT Capital (hedge fund) as a senior-analyst, where
                        he tracked Healthcare space and developed quant-based
                        trading strategies. Deepak holds an MBA from the Indian
                        School of Business. He is also a qualified Chartered
                        Accountant and holds a CFA.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Deepak MODAL*********/}
        {/*********Dinky MODAL***********/}
        <div className="modal" id="DinkyModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Dinky_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">DINKY SANGHVI</div>
                        <div className="stakeholder-position">
                          <b>VP Finance</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Dinky Sanghvi has experience of complete life cycle of
                        PE/VC Fund i.e., Fund raise / closings, Capital call,
                        Disbursement to portfolio companies, exit, distribution
                        to investors and winding-down of Funds At Avataar Dinky
                        is setting up & driving Fund Management functions &
                        building internal Finance foundations as an
                        entrepreneurial journey and will play a key part in
                        helping us build a new format global VC platform.
                      </p>
                      <p>
                        She comes with 15+ years of experience at ICICI Venture
                        Fund Management Co. Ltd. As Vice President Finance, she
                        used to manage Private Equity Funds and Asset Management
                        Company in Finance & operating roles.
                      </p>
                      <p>
                        She is a qualified Chartered Accountant and Company
                        Secretary.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Dinky MODAL*********/}
        {/*********Amit MODAL***********/}
        <div className="modal" id="AmitModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Amit_Kumar_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Amit Kumar</div>
                        <div className="stakeholder-position">
                          <b>Head - Finance COE</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Amit joined the firm in 2022 and currently serves as the
                        Finance COE Lead overseeing the financial and
                        operational process, Standardization of Financial
                        operations, Strategy towards Pre IPO and IPO readiness
                        and Profitability initiatives for the Portfolio
                        companies of Avataar.
                      </p>
                      <p>
                        Prior to joining the firm, Amit was the founding member
                        and Director of LOGIQORE. The firm provided niche CFO
                        Services to early and growth stage SaaS and B2B
                        start-ups in India and ASEAN region helping the
                        companies to re-model their Go-To-Market strategy,
                        streamline their finance operations, cash flow
                        management, raise funds from investors and improve their
                        profitability. Before LOGIQORE, Amit was the CFO of
                        Attune Technologies which is a SaaS Healthcare product
                        company [a Norwest portfolio company]. He has also
                        worked in Leadership positions with multinationals like
                        GE, Oracle, Schweppes and Bosch in India and
                        International region.
                      </p>
                      <p>
                        Amit is a CA and holds an Honors degree in Finance from
                        Magadh University. He also holds certification in
                        leadership and Merger and acquisition from Crotonville.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Amit MODAL*********/}
        {/*********Anirudh MODAL***********/}
        <div className="modal" id="AnirudhModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Anirudh_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Anirudh Singh</div>
                        <div className="stakeholder-position">
                          <b>
                            Investor | M&A And Fundraising | Business Strategy |
                            Team Builder
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Anirudh Singh has been an investor in the Indian
                        technology ecosystem for the last ~15 years. Through his
                        investing career across marquee funds such as Norwest
                        Venture Partners, Alpha Wave Global, Canaan Partners,
                        and B Capital, he has led investment rounds in some of
                        the most successful B2B companies stories in India
                        across food delivery, agri-tech, ed-tech, financial
                        services, marketplaces, gaming etc., across early stage
                        to public markets.
                      </p>
                      <p>
                        His most recent role was as Managing Director at Alpha
                        Wave Global and Head of AWG’s venture growth fund, Alpha
                        Wave Incubation. At AWG, Anirudh was responsible for
                        running the India, UAE, and South East Asia offices of
                        the fund. Prior to joining Alpha Wave Global, Anirudh
                        was an investor with B Capital, a $5b technology
                        investment fund, focused on growth equity investment
                        opportunities in the technology space in India and South
                        East Asia. Prior to that, Anirudh spent 7 years at
                        Norwest Venture Partners, a $9b technology investment
                        fund out of Silicon Valley, covering early stage to
                        public market investments in India across technology,
                        consumer retail, and financial services. Anirudh started
                        his investing career at Canaan Partners, a $10b
                        technology investment fund out of Silicon Valley, where
                        he spent time evaluating early stage investment
                        opportunities in the technology sector in India.
                      </p>
                      <p>
                        Anirudh graduated as an electrical engineer from Thapar
                        University, Patiala, India and completed his MBA from
                        the Indian Institute of Management, Ahmedabad, India
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Anirudh MODAL*********/}
      </Fragment>
    );
  }
}

export default Team;
