import React, { Component, Fragment } from "react";
import "./contact.css";
import { Helmet } from "react-helmet";

class Contact extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>AV CONTACT US</title>
					<meta
						name="description"
						content="
contact@avataar.vc 
 
"
					/>

					<meta
						name="keywords"
						content="
AV ENQUIRY, AVATAAR VENTURES CONTACT

"
					/>
				</Helmet>
				<div className="contact_hero">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 contact_banner_text_bg">
								<h1 className="page_heading">CONTACT US</h1>
								<div className="content_sec_main_contact">
									<div className="contact_content_sec_bangalore">
										<p className="bangalore">BANGALORE </p>
										<p>
											67/1B, 4th Cross, <br />
											Lavelle Rd, <br />
											Bangalore- 560001
										</p>
									</div>

									<div className="contact_content_sec_delhi">
										<p className="bangalore">DELHI </p>
										<p>
											The Quorum Club
											<br />
											2 Horizon Center, First Floor,
											<br />
											Golf Course Road, DLF Phase 5,{" "}
											<br />
											Sector-43, Gurgaon, Haryana - 122002
										</p>
									</div>

									<div className="contact_content_sec_write">
										<div className="row">
											<div
												className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
												style={{ paddingLeft: "0" }}
											>
												<p className="write">
													WRITE TO US
												</p>
												<p className="write_contact">
													contact@avataar.vc
												</p>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 contact_social_left_parent_padding">
												<div className="contact_social_icons">
													<a
														href="https://twitter.com/AvataarVC"
														target="_blank"
													>
														<img
															src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Twitter.svg"
															className="img-fluid"
															alt="avataar_twitter"
														/>
													</a>
													<a
														href="https://www.linkedin.com/company/avataarvc"
														target="_blank"
													>
														<img
															src="https://s3.ap-south-1.amazonaws.com/avataar.vc/LinkedIn.svg"
															className="img-fluid"
															alt="avataar_linkedIn"
														/>
													</a>
												</div>
											</div>
										</div>
									</div>
									<div className="contact_content_sec_write">
										<p>Avataar Ventures is committed to ensure that our employees and external stakeholders can raise their concerns without retaliation or fear of not being heard. Any grievances or issues can be emailed anonymously to <a href="mailto:ethics@avataar.vc"> ethics@avataar.vc</a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Contact;
