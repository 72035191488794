import React, { Fragment, Component } from "react";
import "./portfolio.css";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isZenotiShow: false,
      isCapillaryShow: false,
      isCarmnetShow: false,
      isApnomicShow: false,
      isElasticShow: false,
      isManhatanShow: false,
    };
  }

  zenotiToogle = () => {
    this.setState({
      isZenotiShow: true,
    });
  };

  capillaryToogle = () => {
    this.setState({
      isCapillaryShow: true,
    });
  };

  carmnetToogle = () => {
    this.setState({
      isCarmnetShow: true,
    });
  };

  apnomicToogle = () => {
    this.setState({
      isApnomicShow: true,
    });
  };

  elasticToogle = () => {
    this.setState({
      isElasticShow: true,
    });
  };

  manhattanToggle = () => {
    this.setState({
      isManhatanShow: true,
    });
  };

  closeHandler = () => {
    this.setState({
      isZenotiShow: false,
      isCapillaryShow: false,
      isCarmnetShow: false,
      isApnomicShow: false,
      isElasticShow: false,
      isManhatanShow: false,
    });
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>AV PORTFOLIO COMPANIES</title>
          <meta
            name="description"
            content="
$15 Million + in Annual Recurring Revenues. 
ZENOTI, CAPILLARY TECH, CRM NEXT,HEAL SOFTWARE, ELASTIC RUN, MANTHTAN

"
          />

          <meta
            name="keywords"
            content="
#15million ARR, Annual Recurring Revenues,
Core Operations in India/ South East Asia, next-generation global businesses. ZENOTI, CAPILLARY TECH, CRM NEXT, APPNOMIC, ELASTIC RUN, MANTHTAN"
          />
        </Helmet>
        <div className="background_image_portfolio">
          <div
            className="row home_section"
            id="home"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div
              className="col-sm-12 banner_text custom_banner_text_ap"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h1 className="ethos_banner_heading">FOCUS & PORTFOLIO</h1>
              <div className="home-content mt-5">
                <p className="sub_section portfolio_sub_section">
                  Our focus underlying all the brilliant tech-startup that we
                  are serving today to build the next-gen B2B Products.
                  <br />
                  <div className="portfolio_banner_content portfolio_banner_content_ap">
                    <p className="mt10">
                      <span className="dot" />
                      $15 Million + in Annual Recurring Revenues
                    </p>
                    <p className="mt10">
                      <span className="dot" />
                      Strong Tech-Led B2B & SaaS Companies
                    </p>
                    <p className="mt10">
                      <span className="dot" />
                      Open to Active Partnering
                    </p>

                    <p className="mt10">
                      <span className="dot" />
                      Core Operations in India/ South East Asia
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-sm-12 text-center arrow bounce"
            style={{ position: "absolute", bottom: "20px" }}
          >
            <a href="#ethos">
              <img
                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/arrow.svg"
                style={{ width: "46px", height: "21px" }}
                alt="arrow"
              />
            </a>
          </div>
        </div>
        {/*<div className="row" id="ethos" style={{ height: "20px" }} />*/}
        <div
          id="ethos"
          className="ethos"
          data-aos="fade-up"
          data-aos-duration="100"
        >
          <div className="ethos-framework-para portfolio_framework_para">
            <center>
              <p>OUR PORTFOLIO COMPANIES</p>
            </center>
          </div>
          <div className="portfolio-subheading col-sm-12 mt-3 text-center">
            <p>
              Meet the mountaineers that are scaling new peaks & building the
              next generation of global businesses. Our collective success comes
              from backing these brilliant founders that have managed to build
              meaningful sized businesses despite all odds.
            </p>
          </div>

          <div className="">
            <div className="container">
              <div className="people_flex_container mt-4">
                <div className="row">
                  <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                    <div className="col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.zenotiToogle}
                        data-toggle="modal"
                        data-target="#zenotiModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Zenoti.png"
                          alt="Zenoti"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="zenotiModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Zenoti.png"
                                    alt="Zenoti"
                                    className="displayed img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Having experienced first-hand the difficulty
                                    in running a chain of spa, salon and fitness
                                    centres and not finding software that was
                                    affordable, reliable, easy to use, and
                                    supported the whole ecosystem with a
                                    multi-location chain of a thriving spa and
                                    salon business, Zenoti was founded in 2010.
                                    Today, Zenoti is used by over 5,000
                                    businesses in 32 countries. <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.zenoti.com/"
                                          target="_blank"
                                        >
                                          https://www.zenoti.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.capillaryToogle}
                        data-toggle="modal"
                        data-target="#capillaryModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Capillary.png"
                          alt="Capillary"
                          className="img-fluid cap_img"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="capillaryModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Capillary.png"
                                    alt="Capillary"
                                    className="img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Founded in 2008, Capillary has been at the
                                    forefront of the constant evolution in
                                    consumer experiences. When consumer brands
                                    faced the biggest challenges due to the
                                    inability to capture customer data, identify
                                    consumers visiting their stores and to
                                    communicate effectively with them, the Idea
                                    of Capillary Tech was conceptualised.
                                    Capillary’s products help brands get a 360°
                                    single view of customer and inventory across
                                    all channels, so they could stitch together
                                    previously siloed customer journeys with
                                    data, and build a unified, cross channel
                                    strategies that deliver a consistent,
                                    omnichannel experience.{" "}
                                  </p>
                                  <p>
                                    In this era of Artificial Intelligence, they
                                    continue to innovate and find ways to help
                                    brands stay consumer-ready and deliver
                                    newer, better experiences into the future.
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.capillarytech.com/"
                                          target="_blank"
                                        >
                                          https://www.capillarytech.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.carmnetToogle}
                        data-toggle="modal"
                        data-target="#crmModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/CRM_Next.png"
                          alt="CRM"
                          className="crm_img_class_margin img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="crmModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/CRM_Next.png"
                                    alt="CRM"
                                    className="crm_img_class_margin"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Founded in 2001, CRMNEXT takes it from where
                                    traditional CRMs leave off. From Fortune 500
                                    to start-ups, businesses across nine
                                    verticals have built profitable customer
                                    relationships via CRMNEXT. It eliminates the
                                    artificial barriers between human and
                                    digital channels, enabling world-class,
                                    omnichannel customer interactions from a
                                    single, unified platform. With more than a
                                    million financial service associates and a
                                    billion customers supported by the platform
                                    globally, CRMNEXT effectively recalibrates
                                    banks’ potential to grow engagement, drive
                                    profitability and deliver innovation.
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.crmnext.com/"
                                          target="_blank"
                                        >
                                          https://www.crmnext.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                    <div className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.apnomicToogle}
                        data-toggle="modal"
                        data-target="#appnomicModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/HEAL_Logo.png"
                          alt="Heal Software"
                          className="appnomic-img img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="appnomicModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/HEAL_Logo.png"
                                    alt="Heal Software"
                                    className="img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Founded in 2003, Heal Software helps digital
                                    enterprises achieve uninterrupted
                                    operations. The software enables autonomous
                                    IT operations by predicting and preventing
                                    problems before they happen.
                                    <p>
                                      Heal Software’s unique patented cognitive
                                      capabilities include predictive anomaly
                                      detection, anomaly linked healing triggers
                                      and unified causation that navigates the
                                      linkages between digital journeys,
                                      transactions, Applications and IT
                                      Infrastructure from edge to public and
                                      private cloud.
                                    </p>
                                    <p>
                                      As a category-defining application with
                                      abilities far beyond the AI-assisted APM,
                                      Heal Software elevates digital user
                                      experiences, optimizes IT resources, and
                                      achieves uninterrupted operations for the
                                      enterprises.
                                    </p>
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://healsoftware.ai/"
                                          target="_blank"
                                        >
                                          https://healsoftware.ai/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.elasticToogle}
                        data-toggle="modal"
                        data-target="#elasticModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Elastic_Run.png"
                          alt="Elastic_Run"
                          className="img-fluid elastic_run_image"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="elasticModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Elastic_Run.png"
                                    alt="Elastic_Run"
                                    className="img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Founded in 2016, by veterans in the
                                    Logistics Industry, ElasticRun is an
                                    aggregated variable capacity transportation
                                    network built using idle transportation and
                                    logistics capacities from a large number of
                                    dispersed entrepreneurs and ensure
                                    distribution across sectors and geographies
                                    ensuring last-Mile connectivity.
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.elastic.run/"
                                          target="_blank"
                                        >
                                          https://www.elastic.run/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.manhattanToggle}
                        data-toggle="modal"
                        data-target="#algonomyModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Algonomy-Logo.png"
                          alt="algonomy"
                          className="Algonomy_img_margin img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="algonomyModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Algonomy-Logo.png"
                                    alt="Algonomy"
                                    className="manhatan_img_margin"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Founded in 2003, Algonomy’s mission is to
                                    build sophisticated analytics products
                                    intuitive for customer-facing businesses.
                                    Their focus is on AI, to reimagine how
                                    business can reimagine their relationship
                                    with customers in a way our human
                                    capabilities or intelligence never could.
                                    Manthan partners with the leading data
                                    warehousing, business intelligence and other
                                    firms to offer a complete and better
                                    technology proposition. This makes it easier
                                    for businesses to adopt, integrate and
                                    collaborate on the latest technological
                                    offerings.
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://algonomy.com/"
                                          target="_blank"
                                        >
                                          https://algonomy.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                    <div
                      className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col "
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.zenotiToogle}
                        data-toggle="modal"
                        data-target="#RateGainModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/RateGain_Logo_2020.png"
                          alt="RateGain"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="RateGainModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/RateGain_Logo_2020.png"
                                    alt="RateGain"
                                    className="displayed img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    RateGain is one of world’s leading
                                    travel-tech companies providing Business
                                    Intelligence, Distribution and Social Media
                                    Management products to hospitality clients
                                    globally. RateGain works with 500+ top hotel
                                    brands (e.g. Marriott, Wyndham, and Hyatt),
                                    700+ distribution partners &amp; OTAs (e.g.
                                    Booking.com, Expedia) &amp; 120+ travel
                                    partners (e.g. top airlines, car rentals)
                                    across the globe. <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://rategain.com/"
                                          target="_blank"
                                        >
                                          https://rategain.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col"
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.zenotiToogle}
                        data-toggle="modal"
                        data-target="#SenseModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Sense_Logo.png"
                          alt="Sense"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="SenseModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Sense_Logo.png"
                                    alt="Sense"
                                    className="displayed img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Sense is the leading system of engagement
                                    for the staffing firms globally. It offers a
                                    3-way SaaS platform to connect staffing
                                    firms, contingent talent and enterprises.
                                    Sense empowers contingent staffing firms to
                                    attract, place, retain, and redeploy talent
                                    to opportunities where they are engaged and
                                    productive. Also improves the recruiter
                                    efficiency multi-fold to deliver tangible
                                    RoI <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.sensehq.com/"
                                          target="_blank"
                                        >
                                          https://www.sensehq.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col"
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.zenotiToogle}
                        data-toggle="modal"
                        data-target="#AmagiModal"
                      >
                        <img
                          src="https://letsventure.s3.ap-southeast-1.amazonaws.com/public/logo-blue.png"
                          alt="Amagi"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="AmagiModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://letsventure.s3.ap-southeast-1.amazonaws.com/public/logo-blue.png"
                                    alt="Amagi"
                                    className="displayed img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    Amagi is a provider of cloud-based SaaS
                                    technology for broadcast and connected TV.
                                    It provides a complete suite of solutions
                                    for content creation, distribution, and
                                    monetization to broadcasters, content owners
                                    and distributors. As content consumption and
                                    ad spends shift to connected TV and OTT,
                                    Amagi enables customers to effectively
                                    distribute and monetize their content
                                    libraries with its cutting-edge solutions
                                    and deep integrations with platforms such as
                                    The Roku Channel, Samsung TV Plus, Pluto TV,
                                    Plex, Redbox, STIRR, VIZIO, Xumo, and
                                    others. It currently delivers over 800
                                    channels on its platform and has
                                    distribution in 40 countries across cable,
                                    connected TV, and OTT, and counts marquee
                                    brands like A+E Networks UK, beIN Sports,
                                    CuriosityStream, Discovery Networks, Fox
                                    Networks, Fremantle, Tastemade, Tegna, Vice
                                    Media, and Warner Media as its customers.{" "}
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.amagi.com/"
                                          target="_blank"
                                        >
                                          https://www.amagi.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                    <div className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col">
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.capillaryToogle}
                        data-toggle="modal"
                        data-target="#insuranceDekhoModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/portfolio-image/insurancedekho_logo_Cropped.png"
                          alt="InsuranceDekho"
                          className="img-fluid cap_img"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="insuranceDekhoModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/portfolio-image/insurancedekho_logo_Cropped.png"
                                    alt="InsuranceDekho"
                                    className="img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    InsuranceDekho is a B2B insurtech platform
                                    enabling 90k+ insurance agents. 99% of
                                    Indian insurance is sold online, with agents
                                    facing the challenge of access to
                                    information: multiple verticals, multiple
                                    products, multiple offers. InsuranceDekho
                                    offers a tech platform which consolidates
                                    products from 30+ insurance companies and
                                    provides a single screen view which agents
                                    can use to suggest the best product for
                                    their customers.
                                    <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.insurancedekho.com/"
                                          target="_blank"
                                        >
                                          https://www.insurancedekho.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col"
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.zenotiToogle}
                        data-toggle="modal"
                        data-target="#HealthPlixModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Health_plix_Logo.png"
                          alt="HealthPlix"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="HealthPlixModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Health_plix_Logo.png"
                                    alt="HealthPlix"
                                    className="displayed img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                    HealthPlix has pioneered the digitization of
                                    healthcare through its future-ready
                                    AI-powered EMR platform for doctors in
                                    India. The start-up currently empowers 10K+
                                    doctors to drive better health outcomes for
                                    their patients by providing clinical
                                    decision support at the point of care. More
                                    than 22 million patients have been treated
                                    using the HealthPlix EMR platform by doctors
                                    practicing across 16 specialities.
                                    HealthPlix doctor base and network spans
                                    across 370+ cities in India making it the
                                    trusted EMR platform for doctors in the
                                    country. <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://healthplix.com/"
                                          target="_blank"
                                        >
                                          https://healthplix.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" col-md-3 col-sm-12 col-xs-12 custom_padding_portfolio_col"
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="portfolio_rectangle"
                        // onClick={this.zenotiToogle}
                        data-toggle="modal"
                        data-target="#ChaloModal"
                      >
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Chalo+logo_vv.png"
                          alt="Chalo"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div
                        class="modal fade"
                        id="ChaloModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-lg team_modal_bg"
                          role="document"
                        >
                          <div class="modal-content portfolio_modal_content">
                            <div className="row">
                              <div className="container">
                                <div class="modal-header portfolio_modal_header">
                                  <i
                                    className="lni lni-close"
                                    data-dismiss="modal"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="new_portfolio_content">
                                <div className="col-12">
                                  <img
                                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Chalo+logo_vv.png"
                                    alt="HealthPlix"
                                    className="displayed img-fluid"
                                  />
                                </div>
                                <div clasasName="col-12">
                                  <p className="mt-5">
                                  Chalo is India’s leading bus transport technology company with a presence in 51
                                  cities across 3 countries, powering 150 million rides each month. It was established
                                  in 2014 with the core purpose of making travel better for everyone. The Chalo App is 
                                  India's #1 live bus tracking app with 50 million app sessions each month, and the Chalo
                                  Card is India's most successful bus travel card ever. Chalo partners with bus operators
                                  to deploy this technology and improve the bus travel experience, 
                                  thereby helping to increase bus ridership. For more information, please visit www.chalo.com. <br />
                                    <div className="website_url_container">
                                      <p>
                                        <strong>Website : </strong>
                                        <a
                                          href="https://www.chalo.com/"
                                          target="_blank"
                                        >
                                          https://www.chalo.com/
                                        </a>
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="about_btn_center">
              <center>
                <Link to="/team">
                  <button
                    type="button"
                    className="btn btn-outline-primary portfolio_meet_end_btn_spec"
                  >
                    <div className="flex_btn">
                      <div>MEET THE SCALE-UP TEAM</div>
                      <div>
                        <span>
                          {" "}
                          <i className="fas fa-angle-right" />
                        </span>
                      </div>
                    </div>
                  </button>
                </Link>
              </center>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Portfolio;
