import React, { Fragment, Component } from 'react';
import $ from 'jquery';
import './home.css';
import { Link, withRouter } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import portfolio from '../assets/portfolio.svg';
import playButton from '../assets/playButton.svg';
import operational from '../assets/operational.svg';
import expansion from '../assets/expansion.svg';
// import Modal from "react-animated-modal";
import Modal from 'react-modal';
// import Rodal from "rodal";

// // include styles
// import "rodal/lib/rodal.css";
// import Modal from "react-responsive-modal";
// import "react-responsive-modal/styles.css";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGrowthDivShow: false,
      isOperationDisShow: false,
      isConcentratedDivShow: false,
      options: {
        loop: true,
        margin: 10,
        nav: false,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      },
      options2: {
        loop: false,
        margin: 10,
        nav: false,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 4,
          },
        },
      },
      concentrate_open: false,
      setConcentrateIsOpen: false,
      setOperationsIsOpen: false,
      setGrowthIsOpen: false,
      showModal: false,
      concentrateVisible: false,
    };
  }

  growth_div_toggle = () => {
    if (window.innerWidth < 800) {
      // return e.preventDefault();
      // this.setState({ concentrate_open: true });
      this.setState({
        setGrowthIsOpen: true,
        // showModal: true,
      });
      // this.setState({ concentrateVisible: true });
    }
    // });
    if (window.innerWidth > 800) {
      this.setState({
        isGrowthDivShow: !this.state.isGrowthDivShow,
      });
    }
  };
  operational_div_toggle = () => {
    if (window.innerWidth < 800) {
      // return e.preventDefault();
      // this.setState({ concentrate_open: true });
      this.setState({
        setOperationsIsOpen: true,
        // showModal: true,
      });
      // this.setState({ concentrateVisible: true });
    }
    // });
    if (window.innerWidth > 800) {
      this.setState({
        isOperationDisShow: !this.state.isOperationDisShow,
      });
    }
  };
  concentrated_div_toggle = () => {
    // $("#myModal").on("show.bs.modal", function(e) {
    if (window.innerWidth < 800) {
      // return e.preventDefault();
      // this.setState({ concentrate_open: true });
      this.setState({
        setConcentrateIsOpen: true,
        // showModal: true,
      });
      // this.setState({ concentrateVisible: true });
    }
    // });
    if (window.innerWidth > 800) {
      this.setState({
        isConcentratedDivShow: !this.state.isConcentratedDivShow,
      });
    }
  };
  // onCloseModal = () => {
  // 	this.setState({ concentrate_open: false });
  // };
  closeModal = () => {
    this.setState({
      setConcentrateIsOpen: false,
      setOperationsIsOpen: false,
      setGrowthIsOpen: false,
    });
  };
  closeHandler = () => {
    this.setState({
      isGrowthDivShow: false,
      isOperationDisShow: false,
      isConcentratedDivShow: false,
    });
  };
  hide() {
    this.setState({ concentrateVisible: false });
  }
  pressRouteHandler = () => {
    // localStorage.setItem("press_route", true);
    // window.location.assign("/press");
    this.props.history.push('/press');
  };

  render() {
    // var settings = {
    // 	dots: true,
    // 	infinite: true,
    // 	speed: 500,
    // 	slidesToShow: 3,
    // 	slidesToScroll: 1,
    // };
    return (
      <Fragment>
        <Helmet>
          <title>Avataar Ventures: HOME</title>
          <meta
            name="description"
            content="
Avataar Ventures are Scale-up partners for global expansion. Growth stage, operational fund for tech-led B2B & SaaS companies from India & Southeast Asia. 
"
          />

          <meta
            name="keywords"
            content="
Growth stage, Operational fund, Tech-led B2B & SaaS, Scale-up partners, India & South-East Asia, Action partners, Entrepreneur, VC, Venture Capital, Entrepreneur-VC partnerships, Global success, Core business, Global expansion, Business development, Business building, Global presence, Geo-specific expansion, Management & Strategic recommendations, Deep Business Experience, Active Partnering, Next-generation of Global Businesses, Founding Partners, Global Ambitions, Trusted Partners. VC paradigm shift. Funding, 
"
          />
        </Helmet>
        <div className="background_image_home_page">
          <div className="container">
            <div
              className="row home_section custom_home_section"
              id="home"
              // data-aos="fade-up"
              // data-aos-duration="1500"
            >
              <div
                className="col-sm-4 banner_text banner_text_bg"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <h1 className="page_heading">
                  ACCELERATING TRANSFORMATION&nbsp;
                  <span
                    className="video_modal_icon"
                    data-toggle="modal"
                    data-target="#focusVideoModal"
                  >
                    {/* <i className="fas fa-video" /> */}
                    <img src={playButton} />
                  </span>
                </h1>
                <div className="home-content d-inline-flex mt-5 mt-xs-0 home_custom_top_margin_1440">
                  <div className="sub_section home_content_subsection">
                    <p>
                      Avataar is a{' '}
                      <span className="strong">
                        growth stage, Operational fund
                      </span>{' '}
                      - partnering actively with brilliant regional Tech-led B2B
                      & SaaS startups to Re-invent, Scale & Transform into
                      successful, enduring global businesses.
                    </p>
                    <p>
                      Together, we believe we can scale new peaks and transform
                      India & South-East Asia into product nations, building for
                      the world! So say hello to your scale-up sherpas and
                      goodbye to tons of preparation for the next board meeting.
                    </p>
                  </div>
                </div>
                {/*<button className="btn btn-primary theme_button">PRESS RELEASE</button>*/}
              </div>
            </div>
          </div>

          <div
            className="col-sm-12 text-center arrow bounce mobile_bounce"
            style={{ position: 'absolute', bottom: '20px' }}
          >
            <a href="#ethos">
              <img
                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/arrow.svg"
                style={{ width: '46px', height: '21px' }}
                alt="arrow"
              />
            </a>
          </div>
        </div>
        {/*FOCUS VIDEO MODAL*/}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="modal fade"
                id="focusVideoModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="focusVideoModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div
                    className="modal-content"
                    style={{ background: 'none', border: 'none' }}
                  >
                    <div className="modal-header">
                      {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                      {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> */}
                    </div>
                    <div className="modal-body">
                      <video
                        width="100%"
                        autoplay
                        // width="462"
                        // height="280"
                        controls
                        poster="https://letsventure.s3-ap-southeast-1.amazonaws.com/public/avataarscreen.png"
                        //   preload="metadata"
                      >
                        <source
                          src="https://letsventure.s3-ap-southeast-1.amazonaws.com/public/AVTAAR_WL_LR.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="modal-footer" style={{ display: 'none' }}>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*END FOCUS VIDEO MODAL*/}
        <div
          className="ethos ethos_more_about_inverted_line"
          id="ethos"
          // data-aos="fade-up"
          // data-aos-duration="100"
        >
          <div className="ethos-heading">
            <center>
              <p>ETHOS</p>
            </center>
          </div>

          <div className="ethos-subheading col-sm-12 mt-3 home_ethos_subheading">
            <p>
              Avataar partners are not just VCs on your board, we are scale-up
              sherpas in action – labouring responsibilities, portering our
              learning and enabling the right global networks for our
              entrepreneurs.
            </p>

            <p className="ethos_subheading_second_para">
              Funding is only one part of your journey with us. We have
              orchestrated a paradigm shift in the Entrepreneur-VC equation with
              our{' '}
              <Link to="/ethos">
                <span style={{ color: '#4a90e2' }}>4E framework</span>
              </Link>{' '}
              and are core tenets to shape our collective long-term success. We
              are committed to :
            </p>
          </div>

          <div className="container growth_container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                style={{ padding: '0' }}
              >
                <div
                  className="growth_reactangle mb-3"
                  onClick={this.growth_div_toggle}
                >
                  <div className="ethos-bitmap">
                    <img src={portfolio} alt="growth" />
                  </div>
                  <div className="ethos-title">GROWTH & EXPANSION FOCUS</div>
                  <div className="ethos-content">
                    KNOW MORE
                    <i className="lni lni-chevron-right" />
                  </div>
                </div>
              </div>
              {/*GROWTH MODAL*/}
              {this.state.setGrowthIsOpen ? (
                <div className="first_modal_growth">
                  <Animated
                    animationIn="fadeInDown"
                    animationOut="zoomOutDown"
                    animationInDuration={1000}
                    animationOutDuration={1000}
                    isVisible={this.state.setGrowthIsOpen}
                  >
                    <Modal
                      isOpen={this.state.setGrowthIsOpen}
                      onRequestClose={this.closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false}
                    >
                      <div className="row">
                        <div className="col-8">
                          <span className="ethos-title">
                            GROWTH & EXPANSION FOCUS
                          </span>
                        </div>
                        <div className="col-4">
                          <div
                            className="concentrate_modal_lni_close"
                            onClick={this.closeModal}
                          >
                            <span
                              style={{
                                float: 'right',
                              }}
                              onClick={this.closeModal}
                            >
                              <i
                                className="lni lni-close"
                                // data-dismiss="modal"
                                onClick={this.closeModal}
                              />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: '15px' }}>
                        <div className="col-12 mt-3">
                          <p>
                            We provide growth capital of $10-30M so companies
                            can focus on their core business, not fund-raising.
                            Yet we know global expansion needs more than just
                            funding. The strength of our networks in various
                            geos will unlock the right formula for each market.
                          </p>
                          <p>
                            Beyond our deep global connects, we enable
                            entrepreneurs with hands-on support across all
                            aspects of business building. From best practice,
                            geo-specific GTM playbooks/ frameworks (with active
                            support from our fund’s BD team) to product tweaks,
                            analytics, management & strategic recommendations
                            (leveraging our fund BizOps team).{' '}
                          </p>
                        </div>
                      </div>
                    </Modal>
                  </Animated>
                </div>
              ) : null}
              {/*END GROWTH MODAL*/}

              <div
                className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                style={{ padding: '0' }}
              >
                <div
                  className="operational_reactangle mb-3"
                  onClick={this.operational_div_toggle}
                >
                  <div className="ethos-bitmap">
                    <img src={operational} alt="operational" />
                  </div>

                  <div className="ethos-title">
                    OPERATIONAL <br />
                    MINDSET
                  </div>
                  <div className="ethos-content">
                    KNOW MORE
                    <i className="lni lni-chevron-right" />
                  </div>
                </div>
              </div>
              {/* OPERATIONAL MODAL*/}
              <Animated
                animationIn="fadeInDown"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={this.state.setOperationsIsOpen}
              >
                <Modal
                  isOpen={this.state.setOperationsIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                  ariaHideApp={false}
                >
                  <div className="row">
                    <div className="col-8">
                      <span className="ethos-title">OPERATIONAL MINDSET</span>
                    </div>
                    <div className="col-4">
                      <div
                        className="concentrate_modal_lni_close"
                        onClick={this.closeModal}
                      >
                        <span
                          style={{ float: 'right' }}
                          onClick={this.closeModal}
                        >
                          <i
                            className="lni lni-close"
                            // data-dismiss="modal"
                            onClick={this.closeModal}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '15px' }}>
                    <div className="col-12 mt-3">
                      <p>
                        As execution partners with an action bias, we’re ready
                        to roll up our sleeves for you. You still decide the
                        direction; we bring timely learnings & relevant context
                        from our past successes & failures. Partners at Avataar
                        have had several operating stints as executives at
                        marquee SaaS companies, with the largest ones reaching
                        multiple billions of dollars of enterprise value.
                      </p>
                      <p>
                        Coupled with the founder’s passion, these learnings help
                        our companies accelerate together; and back companies in
                        developing their own scale-up success formula from $10M
                        to $100M in ARR.{' '}
                      </p>
                    </div>
                  </div>
                </Modal>
              </Animated>
              {/*END OPERATONAL MAODAL*/}
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                style={{ padding: '0' }}
              >
                <div
                  className="concentrated_reactangle mb-3"
                  onClick={this.concentrated_div_toggle}
                  // data-toggle="modal"
                  // data-target="#myModal"
                  // id="mymodal"
                >
                  <div className="ethos-bitmap">
                    <img src={expansion} alt="concentrated" />
                  </div>

                  <div className="ethos-title">
                    CONCENTRATED <br />
                    PORTFOLIO
                  </div>
                  <div className="ethos-content">
                    KNOW MORE
                    <i className="lni lni-chevron-right" />
                  </div>
                </div>
              </div>
              {/*CONCENTRATE MODAL*/}
              {/*<Modal
								visible={this.state.showModal}
								closemodal={() =>
									this.setState({
										showModal: false,
									})
								}
								type="flipInX"
							>
								Some text or JSX inside modal goes here...
							</Modal>*/}
              {/*<Rodal
								visible={this.state.concentrateVisible}
								onClose={this.hide.bind(this)}
								animation="slideDown"
							>
								<div className="col-12">
									<span className="ethos-title">
										CONCENTRATED PORTFOLIO
									</span>
								</div>
								<div className="col-12 mt-3">
									<p>
										Having been in your shoes before, we
										know focus matters & every company’s
										journey is unique. To maximize every
										partnership, we invest more in less.
									</p>
									<p>
										We commit only on 8-10 investments per
										fund. We concentrate exclusively on
										Tech-led B2B & SaaS Companies where we
										have deep experience.
									</p>
								</div>{" "}
							</Rodal>*/}
              <Animated
                animationIn="fadeInDown"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={this.state.setConcentrateIsOpen}
              >
                <Modal
                  isOpen={this.state.setConcentrateIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                  ariaHideApp={false}
                >
                  <div className="row">
                    <div className="col-8">
                      <span className="ethos-title">
                        CONCENTRATED PORTFOLIO
                      </span>
                    </div>
                    <div className="col-4">
                      <div
                        className="concentrate_modal_lni_close"
                        onClick={this.closeModal}
                      >
                        <span
                          style={{ float: 'right' }}
                          onClick={this.closeModal}
                        >
                          <i
                            className="lni lni-close"
                            // data-dismiss="modal"
                            onClick={this.closeModal}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '15px' }}>
                    <div className="col-12 mt-3">
                      <p>
                        Having been in your shoes before, we know focus matters
                        & every company’s journey is unique. To maximize every
                        partnership, we invest more in less.
                      </p>
                      <p>
                        We commit only on 8-10 investments per fund. We
                        concentrate exclusively on Tech-led B2B & SaaS Companies
                        where we have deep experience.
                      </p>
                    </div>
                  </div>
                </Modal>
              </Animated>

              {/*<Modal
								open={this.state.concentrate_open}
								onClose={this.onCloseModal}
								center
							>
								<h2>Simple centered modal</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Nullam pulvinar risus non
									risus hendrerit venenatis. Pellentesque sit
									amet hendrerit risus, sed porttitor quam.
								</p>
							</Modal>*/}

              {/*END CONCENTRATE MDOAL*/}

              {/*{this.state.isGrowthDivShow ? (
								<div
									className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div
										className="growth_reactangle_toogle_div mb-3"
										onClick={this.growth_div_toggle}
									>
										<div className="ethos-bitmap">
											<img
												src={require("../assets/portfolio.svg")}
												alt="growth"
											/>
										</div>
										<div className="ethos-title">
											GROWTH & EXPANSION FOCUS
										</div>
										<div className="ethos-content">
											KNOW MORE
											<i className="fas fa-angle-right"></i>
										</div>
									</div>
								</div>
							) : null}*/}
            </div>
          </div>

          {this.state.isGrowthDivShow ? (
            <div className="toggle_elements">
              <div className="container">
                <div className="row">
                  <div className="col-12" style={{ padding: '0' }}>
                    <div className="growth_reactangle_toogle_div mb-3">
                      <Animated
                        animationIn="fadeInLeft"
                        animationOut="fadeOutLeft"
                        animationInDuration={100}
                        animationOutDuration={100}
                        isVisible={this.state.isGrowthDivShow}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <span className="ethos-title">
                              GROWTH & EXPANSION FOCUS
                            </span>

                            <i
                              className="lni lni-close"
                              onClick={this.closeHandler}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0">
                            <div className="ethos-bitmap">
                              <img src={portfolio} alt="growth" />
                            </div>
                          </div>

                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <p>
                              We provide growth capital of $10-30M so companies
                              can focus on their core business, not
                              fund-raising. Yet we know global expansion needs
                              more than just funding. The strength of our
                              networks in various geos will unlock the right
                              formula for each market.
                            </p>
                            <p>
                              Beyond our deep global connects, we enable
                              entrepreneurs with hands-on support across all
                              aspects of business building. From best practice,
                              geo-specific GTM playbooks/ frameworks (with
                              active support from our fund’s BD team) to product
                              tweaks, analytics, management & strategic
                              recommendations (leveraging our fund BizOps team).{' '}
                            </p>
                          </div>
                        </div>
                      </Animated>
                    </div>

                    {/*<div className="ethos-bitmap">
													<img
														src={require("../assets/portfolio.svg")}
														alt="growth"
													/>
													<span
														onClick={
															this.closeHandler
														}
													>
														<i className="fas fa-times"></i>
													</span>
												</div>*/}
                    {/*<div className="ethos-title">
													GROWTH & EXPANSION FOCUS
												</div>*/}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.isOperationDisShow ? (
            <div className="toggle_elements">
              <div className="container">
                <div className="row">
                  <div className="col-12" style={{ padding: '0' }}>
                    <div className="operation_reactangle_toogle_div mb-3">
                      <Animated
                        animationIn="fadeInLeft"
                        animationOut="fadeOutLeft"
                        animationInDuration={100}
                        animationOutDuration={100}
                        isVisible={this.state.isOperationDisShow}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <span className="ethos-title">
                              OPERATIONAL MINDSET
                            </span>
                            <i
                              className="lni lni-close"
                              onClick={this.closeHandler}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="ethos-bitmap">
                              <img src={operational} alt="operational" />
                            </div>
                          </div>

                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <p>
                              As execution partners with an action bias, we’re
                              ready to roll up our sleeves for you. You still
                              decide the direction; we bring timely learnings &
                              relevant context from our past successes &
                              failures. Partners at Avataar have had several
                              operating stints as executives at marquee SaaS
                              companies, with the largest ones reaching multiple
                              billions of dollars of enterprise value.
                            </p>
                            <p>
                              Coupled with the founder’s passion, these
                              learnings help our companies accelerate together;
                              and back companies in developing their own
                              scale-up success formula from $10M to $100M in
                              ARR.{' '}
                            </p>
                          </div>
                        </div>
                      </Animated>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.isConcentratedDivShow ? (
            <div className="toggle_elements">
              <div className="container">
                <div className="row">
                  <div className="col-12" style={{ padding: '0' }}>
                    <div className="concentrated_reactangle_toogle_div mb-3">
                      <Animated
                        animationIn="fadeInLeft"
                        animationOut="fadeOutLeft"
                        animationInDuration={100}
                        animationOutDuration={100}
                        isVisible={this.state.isConcentratedDivShow}
                      >
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-sm-0 col-xs-0" />
                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <span className="ethos-title">
                              CONCENTRATED PORTFOLIO
                            </span>
                            <i
                              className="lni lni-close"
                              onClick={this.closeHandler}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="ethos-bitmap">
                              <img src={expansion} alt="concentrated" />
                            </div>
                          </div>

                          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <p>
                              Having been in your shoes before, we know focus
                              matters & every company’s journey is unique. To
                              maximize every partnership, we invest more in
                              less.
                            </p>
                            <p>
                              We commit only on 8-10 investments per fund. We
                              concentrate exclusively on Tech-led B2B & SaaS
                              Companies where we have deep experience.
                            </p>
                          </div>
                        </div>{' '}
                      </Animated>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/*<div className="container">
						<div className="ethos-flex-container mt-5">
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 growth_parent_col">
								<div
									data-aos="zoom-in-right"
									data-aos-duration="1000"
								>
									<div className="growth_reactangle">
										<div className="ethos-bitmap">
											<img
												src={require("../assets/portfolio.svg")}
												alt="growth"
											/>
										</div>
										<div className="ethos-title">
											GROWTH & EXPANSION FOCUS
										</div>
										<div className="ethos-content">
											KNOW MORE
											<i className="fas fa-angle-right"></i>
										</div>
									</div>
									<div className="growth_reactangle growth_rectangle_overlap"></div>
								</div>
							</div>

							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 operational_parent_col">
								<div
									data-aos="zoom-in-right"
									data-aos-duration="1500"
								>
									<div className="operational_reactangle">
										<div className="ethos-bitmap">
											<img
												src={require("../assets/operational.svg")}
												alt="operational"
											/>
										</div>

										<div className="ethos-title">
											OPERATIONAL MINDSET
										</div>
										<div className="ethos-content">
											KNOW MORE
											<i className="fas fa-angle-right"></i>
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 col-md-4 col-sm-12 col-xs-12 concentrated_parent_col"
								style={{ paddingLeft: "0" }}
							>
								<div
									data-aos="zoom-in-right"
									data-aos-duration="2000"
								>
									<div className="concentrated_reactangle">
										<div className="ethos-bitmap">
											<img
												src={require("../assets/expansion.svg")}
												alt="concentrated"
											/>
										</div>

										<div className="ethos-title">
											CONCENTRATED PORTFOLIO
										</div>
										<div className="ethos-content">
											KNOW MORE
											<i className="fas fa-angle-right"></i>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>*/}

          <div className="row">
            <div className="container">
              <div className="about_btn_center">
                <center>
                  <Link to="/ethos">
                    <button
                      type="button"
                      className="btn btn-outline-primary content_end_btn_spec"
                    >
                      {' '}
                      <div className="btn_flex">
                        <div>ABOUT OUR 4E FRAMEWORK </div>
                        <div>
                          <span>
                            {' '}
                            <i className="lni lni-chevron-right" />
                          </span>
                        </div>
                      </div>
                    </button>
                  </Link>
                </center>
              </div>
            </div>
          </div>
          {/*<div className="ethos_more_about_inverted_line"></div>*/}
        </div>

        {/*FOCUS SECTION*/}
        {/*<div
					className="row"
					id="focus"
					style={{ height: "20px" }}
				></div>*/}
        <div className="focus-container">
          <div className="focus" data-aos="fade-up" data-aos-duration="1500">
            <div className="ethos-heading">
              <center>
                <p>FOCUS</p>
              </center>
            </div>
            <div className="focus-subheading mt-5">
              We focus on brilliant companies with robust teams, who harbour
              global ambitions. <br />
            </div>
            <div className="focus-subheading">We Look For:</div>
            <div className="focus_left_subheading mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div
                      className="focus-content"
                      // style={{
                      //   position: "absolute",
                      //   top: "50%",
                      //   left: "0",
                      //   transform: "translate(0, -50%)",
                      // }}
                    >
                      <p>
                        <span className="light_strong">$15 Million</span> + in
                        Annual Recurring Revenues
                      </p>
                      <p>
                        Strong
                        <span className="light_strong">
                          {' '}
                          Tech-Led B2B & SaaS
                        </span>{' '}
                        Companies
                      </p>
                      <p>
                        Open to{' '}
                        <span className="light_strong">Active Partnering</span>
                      </p>
                      <p style={{ marginBottom: '0' }}>
                        Core Operations in
                        <span className="light_strong">
                          {' '}
                          India/ South East Asia
                        </span>
                      </p>
                      {/* <span className="light_strong">$15 Million</span> + in
                      Annual Recurring Revenues
                      <br />
                      Strong
                      <span className="light_strong">
                        {" "}
                        Tech-Led B2B & SaaS
                      </span>{" "}
                      Companies
                      <br />
                      Open to{" "}
                      <span className="light_strong">Active Partnering</span>
                      <br />
                      Core Operations in
                      <span className="light_strong">
                        {" "}
                        India/ South East Asia
                      </span>
                      <br /> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 focus_video_bg">
                    {/* <iframe src='https://letsventure.s3-ap-southeast-1.amazonaws.com/public/AVTAAR_WL_LR.mp4'
					width="462" height="280"
        // frameborder='0'
        // allow='autoplay; encrypted-media'
        allowfullscreen
        // title='video'
/> */}
                    <video
                      className="focus_video"
                      controls
                      poster="https://letsventure.s3-ap-southeast-1.amazonaws.com/public/avataarscreen.png"
                      //   preload="metadata"
                    >
                      <source
                        src="https://letsventure.s3-ap-southeast-1.amazonaws.com/public/AVTAAR_WL_LR.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*PORTFOLIO SECTION */}
        <div className="row pt-5 pb-5 custom_portfolio_bg_ap" id="portfolio">
          <div
            className="col-sm-12 text-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="ethos-heading">
              <p>PORTFOLIO Section</p>
            </div>
            <p className="theme_sub_heading mt-3">
              The mountaineers scaling the new peaks & building the
              next-generation of global <br />
              businesses.
            </p>
            <div className="row">
              <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#zenotiModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Zenoti.png"
                      alt="Zenoti"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#elasticModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Elastic_Run.png"
                      alt="Elastic_Run"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#capillaryModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Capillary.png"
                      alt="Capillary"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel "
                    data-toggle="modal"
                    data-target="#algonomyModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Algonomy-Logo.png"
                      alt="Algonomy"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel "
                    data-toggle="modal"
                    data-target="#crmModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/CRM_Next.png"
                      alt="CRM"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel "
                    data-toggle="modal"
                    data-target="#appnomicModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/HEAL_Logo.png"
                      alt="Heal Software"
                      className="appnomic-img img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#RateGainModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/RateGain_Logo_2020.png"
                      alt="RateGain"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#SenseModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Sense_Logo.png"
                      alt="Sense"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>

                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#AmagiModal"
                  >
                    <img
                      src="https://letsventure.s3.ap-southeast-1.amazonaws.com/public/logo-blue.png"
                      alt="Amagi"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center col-sm-12 flex-wrap">
                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#insuranceDekhoModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/portfolio-image/insurancedekho_logo_Cropped.png"
                      alt="InsuranceDekho"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#healthplixModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Health_plix_Logo.png"
                      alt="HealthPlix"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>
                <div className="col-md-3 col-xs-12 col-sm-12 ">
                  <div
                    className="portfolio_ap_carousel"
                    data-toggle="modal"
                    data-target="#ChaloModal"
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Chalo+logo_vv.png"
                      alt="Chalo"
                      className="img-fluid appnomic-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* healthplix Modal */}
            <div
              class="modal fade"
              id="healthplixModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Health_plix_Logo.png"
                          alt=""
                          className="displayed img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          HealthPlix has pioneered the digitization of
                          healthcare through its future-ready AI-powered EMR
                          platform for doctors in India. The start-up currently
                          empowers 10K+ doctors to drive better health outcomes
                          for their patients by providing clinical decision
                          support at the point of care. More than 22 million
                          patients have been treated using the HealthPlix EMR
                          platform by doctors practicing across 16 specialities.
                          HealthPlix doctor base and network spans across 370+
                          cities in India making it the trusted EMR platform for
                          doctors in the country. <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a href="https://healthplix.com/" target="_blank">
                                https://healthplix.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end healthplix Modal */}
            {/* insuranceDekho Modal */}
            <div
              class="modal fade"
              id="insuranceDekhoModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/portfolio-image/insurancedekho_logo_Cropped.png"
                          alt="Zenoti"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          InsuranceDekho is a B2B insurtech platform enabling
                          90k+ insurance agents. 99% of Indian insurance is sold
                          online, with agents facing the challenge of access to
                          information: multiple verticals, multiple products,
                          multiple offers. InsuranceDekho offers a tech platform
                          which consolidates products from 30+ insurance
                          companies and provides a single screen view which
                          agents can use to suggest the best product for their
                          customers. <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a
                                href="https://www.insurancedekho.com/"
                                target="_blank"
                              >
                                https://www.insurancedekho.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end insuranceDekho Modal */}
            {/*ZENOTI MODAL*/}
            <div
              class="modal fade"
              id="RateGainModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/RateGain_Logo_2020.png"
                          alt="RateGain"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          RateGain is one of world’s leading travel-tech
                          companies providing Business Intelligence,
                          Distribution and Social Media Management products to
                          hospitality clients globally. RateGain works with 500+
                          top hotel brands (e.g. Marriott, Wyndham, and Hyatt),
                          700+ distribution partners &amp; OTAs (e.g.
                          Booking.com, Expedia) &amp; 120+ travel partners (e.g.
                          top airlines, car rentals) across the globe. <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a href="https://rategain.com/" target="_blank">
                                https://rategain.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*END ZENOTI MODAL*/}
            {/*ZENOTI MODAL*/}
            <div
              class="modal fade"
              id="SenseModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Sense_Logo.png"
                          alt="Sense"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Sense is the leading system of engagement for the
                          staffing firms globally. It offers a 3-way SaaS
                          platform to connect staffing firms, contingent talent
                          and enterprises. Sense empowers contingent staffing
                          firms to attract, place, retain, and redeploy talent
                          to opportunities where they are engaged and
                          productive. Also improves the recruiter efficiency
                          multi-fold to deliver tangible RoI <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a
                                href="https://www.sensehq.com/"
                                target="_blank"
                              >
                                https://www.sensehq.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*END ZENOTI MODAL*/}
            {/*ZENOTI MODAL*/}
            <div
              class="modal fade"
              id="zenotiModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Zenoti.png"
                          alt="Zenoti"
                          className="displayed img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Having experienced first-hand the difficulty in
                          running a chain of spa, salon and fitness centres and
                          not finding software that was affordable, reliable,
                          easy to use, and supported the whole ecosystem with a
                          multi-location chain of a thriving spa and salon
                          business, Zenoti was founded in 2010. Today, Zenoti is
                          used by over 5,000 businesses in 32 countries. <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a href="https://www.zenoti.com/" target="_blank">
                                https://www.zenoti.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*END ZENOTI MODAL*/}
            {/*CAPILLARY MODAL*/}
            <div
              class="modal fade"
              id="capillaryModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Capillary.png"
                          alt="Capillary"
                          className="img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Founded in 2008, Capillary has been at the forefront
                          of the constant evolution in consumer experiences.
                          When consumer brands faced the biggest challenges due
                          to the inability to capture customer data, identify
                          consumers visiting their stores and to communicate
                          effectively with them, the Idea of Capillary Tech was
                          conceptualised. Capillary’s products help brands get a
                          360° single view of customer and inventory across all
                          channels, so they could stitch together previously
                          siloed customer journeys with data, and build a
                          unified, cross channel strategies that deliver a
                          consistent, omnichannel experience.{' '}
                        </p>
                        <p>
                          In this era of Artificial Intelligence, they continue
                          to innovate and find ways to help brands stay
                          consumer-ready and deliver newer, better experiences
                          into the future.
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a
                                href="https://www.capillarytech.com/"
                                target="_blank"
                              >
                                https://www.capillarytech.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*END CAPILLARY MODAL*/}
            {/*CRM MODAL*/}
            <div
              class="modal fade"
              id="crmModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/CRM_Next.png"
                          alt="CRM"
                          className="crm_img_class_margin"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Founded in 2001, CRMNEXT takes it from where
                          traditional CRMs leave off. From Fortune 500 to
                          start-ups, businesses across nine verticals have built
                          profitable customer relationships via CRMNEXT. It
                          eliminates the artificial barriers between human and
                          digital channels, enabling world-class, omnichannel
                          customer interactions from a single, unified platform.
                          With more than a million financial service associates
                          and a billion customers supported by the platform
                          globally, CRMNEXT effectively recalibrates banks’
                          potential to grow engagement, drive profitability and
                          deliver innovation.
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a
                                href="https://www.crmnext.com/"
                                target="_blank"
                              >
                                https://www.crmnext.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*END CRM MODAL*/}
            {/*APPNOMIC MODAL*/}
            <div
              class="modal fade"
              id="appnomicModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/HEAL_Logo.png"
                          alt="Heal Software"
                          className="img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Founded in 2003, Heal Software helps digital
                          enterprises achieve uninterrupted operations. The
                          software enables autonomous IT operations by
                          predicting and preventing problems before they happen.
                          <p>
                            Heal Software’s unique patented cognitive
                            capabilities include predictive anomaly detection,
                            anomaly linked healing triggers and unified
                            causation that navigates the linkages between
                            digital journeys, transactions, Applications and IT
                            Infrastructure from edge to public and private
                            cloud.
                          </p>
                          <p>
                            As a category-defining application with abilities
                            far beyond the AI-assisted APM, Heal Software
                            elevates digital user experiences, optimizes IT
                            resources, and achieves uninterrupted operations for
                            the enterprises.
                          </p>
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a
                                href="https://healsoftware.ai/"
                                target="_blank"
                              >
                                https://healsoftware.ai/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*END APPNOMIC MODAL*/}
            {/*ELASTIC MODAL*/}
            <div
              class="modal fade"
              id="elasticModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Elastic_Run.png"
                          alt="Elastic_Run"
                          className="img-fluid"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Founded in 2016, by veterans in the Logistics
                          Industry, ElasticRun is an aggregated variable
                          capacity transportation network built using idle
                          transportation and logistics capacities from a large
                          number of dispersed entrepreneurs and ensure
                          distribution across sectors and geographies ensuring
                          last-Mile connectivity.
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a
                                href="https://www.elastic.run/"
                                target="_blank"
                              >
                                https://www.elastic.run/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*END ELASTIC MODAL*/}
            {/*MANTHAN MODAL*/}
            <div
              class="modal fade"
              id="algonomyModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Algonomy-Logo.png"
                          alt="Algonomy"
                          className="manhatan_img_margin"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Founded in 2003, Algonomy’s mission is to build
                          sophisticated analytics products intuitive for
                          customer-facing businesses. Their focus is on AI, to
                          reimagine how business can reimagine their
                          relationship with customers in a way our human
                          capabilities or intelligence never could. Manthan
                          partners with the leading data warehousing, business
                          intelligence and other firms to offer a complete and
                          better technology proposition. This makes it easier
                          for businesses to adopt, integrate and collaborate on
                          the latest technological offerings.
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a href="https://algonomy.com/" target="_blank">
                                https://algonomy.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*END MMANTHAN MODAL*/}
            <div
              class="modal fade"
              id="AmagiModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://letsventure.s3.ap-southeast-1.amazonaws.com/public/logo-blue.png"
                          alt="Amagi"
                          className="manhatan_img_margin"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Amagi is a provider of cloud-based SaaS technology for
                          broadcast and connected TV. It provides a complete
                          suite of solutions for content creation, distribution,
                          and monetization to broadcasters, content owners and
                          distributors. As content consumption and ad spends
                          shift to connected TV and OTT, Amagi enables customers
                          to effectively distribute and monetize their content
                          libraries with its cutting-edge solutions and deep
                          integrations with platforms such as The Roku Channel,
                          Samsung TV Plus, Pluto TV, Plex, Redbox, STIRR, VIZIO,
                          Xumo, and others. It currently delivers over 800
                          channels on its platform and has distribution in 40
                          countries across cable, connected TV, and OTT, and
                          counts marquee brands like A+E Networks UK, beIN
                          Sports, CuriosityStream, Discovery Networks, Fox
                          Networks, Fremantle, Tastemade, Tegna, Vice Media, and
                          Warner Media as its customers.
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a href="https://www.amagi.com/" target="_blank">
                                https://www.amagi.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id="ChaloModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg team_modal_bg" role="document">
                <div class="modal-content portfolio_modal_content home_portfolio_modal_content">
                  <div className="row">
                    <div className="container">
                      <div class="modal-header portfolio_modal_header">
                        <i className="lni lni-close" data-dismiss="modal" />
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div className="new_portfolio_content">
                      <div className="col-12">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Chalo+logo_vv.png"
                          alt="Chalo"
                          className="manhatan_img_margin"
                        />
                      </div>
                      <div clasasName="col-12">
                        <p className="mt-5">
                          Chalo is India’s leading bus transport technology
                          company with a presence in 51 cities across 3
                          countries, powering 150 million rides each month. It
                          was established in 2014 with the core purpose of
                          making travel better for everyone. The Chalo App is
                          India's #1 live bus tracking app with 50 million app
                          sessions each month, and the Chalo Card is India's
                          most successful bus travel card ever. Chalo partners
                          with bus operators to deploy this technology and
                          improve the bus travel experience, thereby helping to
                          increase bus ridership.
                          <br />
                          <div className="website_url_container">
                            <p>
                              <strong>Website : </strong>
                              <a href="https://www.chalo.com/" target="_blank">
                                https://www.chalo.com/
                              </a>
                            </p>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="portfolio_btn_center">
              <center>
                <Link to="/portfolio">
                  <button
                    type="button"
                    className="btn btn-outline-primary content_end_btn_spec"
                  >
                    KNOW MORE
                    <span>
                      <i className="lni lni-chevron-right" />
                    </span>
                  </button>
                </Link>
              </center>
            </div>
          </div>
        </div>
        <div
          className="row media_section_parent_div"
          id="founder_circle"
          style={{ backgroundColor: '#ffe' }}
        />
        <div className="media_cust_bg_ap" style={{ backgroundColor: '#ffe' }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ethos-heading">
                  <center>
                    <p>FOUNDER CIRCLE</p>
                  </center>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 custom_owl_item_width">
                <div
                  id="founder_circle_section"
                  className="owl-carousel owl-theme mt-5 mb-5"
                >
                  <div className="item">
                    <div className="col-12">
                      <div
                        className="founder_circle_team_img_container"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="portfolio_ap_carousel company_logo_container"
                          style={{ marginTop: 0 }}
                        >
                          <img
                            src="https://letsventure.s3.ap-southeast-1.amazonaws.com/public/logo-blue.png"
                            alt="Amagi"
                            className="img-fluid"
                          />
                        </div>
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Founder_circle_image/bhaskar_subramanian_Cropped.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            Baskar Subramanian
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Co-Founder and CEO​
                            </span>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <div
                        className="founder_circle_team_img_container"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="portfolio_ap_carousel company_logo_container"
                          style={{ marginTop: 0 }}
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Capillary.png"
                            alt="Capillary"
                            className="img-fluid"
                          />
                        </div>
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Founder_circle_image/aneesh_ready_Cropped.png"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            Aneesh Reddy
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Co-Founder and CEO​
                            </span>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <div
                        className="founder_circle_team_img_container"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="portfolio_ap_carousel company_logo_container"
                          style={{ marginTop: 0 }}
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Elastic_Run.png"
                            alt="Elastic Run"
                            className="img-fluid"
                          />
                        </div>
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Founder_circle_image/sandeep_deshmukh_Cropped.png"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            Sandeep Deshmukh
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Co-Founder and CEO​
                            </span>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <div
                        className="founder_circle_team_img_container"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="portfolio_ap_carousel company_logo_container"
                          style={{ marginTop: 0 }}
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/RateGain_Logo_2020.png"
                            alt="RateGain"
                            className="img-fluid"
                          />
                        </div>
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Founder_circle_image/Bhanu_chopra_Cropped.png"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            Bhanu Chopra
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Founder and CEO​
                            </span>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <div
                        className="founder_circle_team_img_container"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="portfolio_ap_carousel company_logo_container"
                          style={{ marginTop: 0 }}
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Zenoti.png"
                            alt="Zenoti"
                            className="img-fluid"
                          />
                        </div>
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Founder_circle_image/sudheer_koneru_Cropped.png"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            Sudheer Koneru
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Founder and CEO​
                            </span>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>

                  <div className="item">
                    <div className="col-12">
                      <div
                        className="founder_circle_team_img_container"
                        style={{ cursor: 'pointer' }}
                      >
                        <div
                          className="portfolio_ap_carousel company_logo_container"
                          style={{ marginTop: 0 }}
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/portfolio-image/Classplus-Logo.webp"
                            alt="Classplus"
                            className="img-fluid"
                          />
                        </div>
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Founder_circle_image/Mukul+photo+_Cropped.png"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="overlay george-overlay">
                          <div className="text">
                            Mukul Rustagi
                            <br />
                            <span
                              style={{
                                fontWeight: '400',
                              }}
                            >
                              Founder of ClassPlus
                            </span>
                          </div>
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*TEAM SECTION*/}
        <div className="inverted_line" />
        <div
          className="row"
          id="people"
          // style={{ height: "40px" }}
        />
        <div className="people_section pt-5 pb-5">
          <div className="row text-center">
            <div
              className="col-sm-12"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="ethos-heading">
                <center>
                  <p>TEAM</p>
                </center>
              </div>
            </div>
          </div>
          <div
            className="people_container"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <p className="theme_sub_heading team_heading mt-3">
              The Avataar Ventures team started its journey with two founding
              partners who are seasoned campaigners in the tech & startup space.
              Having successfully led our own global-scale journeys, we are now
              committed to help create a broader impact by building a
              world-class team that will actively support you on different
              fronts & in different parts of the world.
            </p>
          </div>

          <div className="row d-flex flex-wrap justify-content-center mt-5">
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#AkshitaModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Akshita_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    AKSHITA SHAH
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Associate
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#AmitModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Amit_Kumar_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    Amit Kumar
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Head - Finance COE
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#AnirudhModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Anirudh_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    Anirudh Singh
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Partner
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-center mt-5">
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#DeepakModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Deepak+Rathi_image_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    DEEPAK RATHI
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Analyst
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#DinkyModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Dinky_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    DINKY SANGHVI
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      VP Finance
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#DiwakarModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Diwakar_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    DIWAKAR ANAND
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Senior Associate
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-center mt-5">
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#ganeshModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Ganesh_Nayak_Avataar+-78_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    GANESH NAYAK
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Fundraising and Capital Markets
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
              <div className="text-center card_wrapper">
                <div
                  className=""
                  data-toggle="modal"
                  data-target="#georgeModal"
                >
                  <div className="parent_team_img_container">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/George+_Cropped2.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="overlay george_seperaate_component">
                      <div className="text">
                        GEORGE THANGADURAI
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Operating Partner - Fund Management & Compliance
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12 "
              data-toggle="modal"
              data-target="#krishModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Krishen_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/george_color.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/george1-black_white.png'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    KRISH KUPATHIL
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Venture Partner
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-center mt-md-5">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
              <div className="text-center card_wrapper">
                <div className="" data-toggle="modal" data-target="#mikeModal">
                  <div className="parent_team_img_container parent_team_img_container_height">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mike-mulica_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="overlay george-overlay">
                      <div className="text">
                        MIKE MULICA
                        <br />
                        <span
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          Operating Partner
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#mohanModal"
            >
              <div className="text-center card_wrapper">
                <div className="parent_team_img_container">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mohan_Cropped.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="overlay overlay_text_width">
                    <div className="text">
                      MOHAN KUMAR <br />
                      <span
                        style={{
                          fontWeight: '400',
                        }}
                      >
                        Managing Partner
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#nishantModal"
            >
              <div className="parent_team_img_container parent_team_img_container_height">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Nishant_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="overlay">
                  <div className="text">
                    NISHANT RAO
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Founding Partner
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-center mt-md-5">
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#shobhitModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Shobhit_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    SHOBHIT GUPTA
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Principal
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#spurthiModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Spurthi_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    SPURTHI NAIDU
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      People & Operation Manager
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
            <div
              class="item col-md-3 col-sm-12 col-xs-12"
              data-toggle="modal"
              data-target="#subhroModal"
            >
              <div className="parent_team_img_container">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Subhro_Cropped.jpg"
                  className="card-img-top"
                  alt="..."
                  // onmouseover="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica.jpg'"
                  // onmouseout="this.src='https://s3.ap-south-1.amazonaws.com/avataar.vc/Mike-mulica-ConvertImage.jpg'"
                />
                <div className="overlay george-overlay">
                  <div className="text">
                    SUBHRO SENGUPTA
                    <br />
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      Associate
                    </span>
                  </div>
                </div>
              </div>{' '}
            </div>
          </div>
        </div>

        {/***MEDIA SECTION*******/}
        <div className="row media_section_parent_div" id="media" />
        <div className="media_cust_bg_ap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ethos-heading">
                  <center>
                    <p>IN THE MEDIA</p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="media_section_content"
          style={{ background: '#fff' }}
        >
          <div className="container custom_media_card_container">
            <div className="row">
              <div className="col-12">
                <OwlCarousel className="owl-theme" {...this.state.options}>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://www.financialexpress.com/healthcare/healthplix-this-startup-helps-doctors-digitise-their-practice-heres-how/3131034/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Media_Image/healthpixteam_img.jpg"
                            alt="Card cap"
                          />
                          <div className="card-body">
                            <p className="card-title">19 June 2023</p>
                            <p className="card-text">
                              HEALTHPLIX: This startup helps doctors digitise
                              their practice – Here’s how
                            </p>
                            <a
                              href="https://www.financialexpress.com/healthcare/healthplix-this-startup-helps-doctors-digitise-their-practice-heres-how/3131034/"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://chalo.com/news/fundraise2023"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/fundraise.png"
                            alt="Card cap"
                          />
                          <div className="card-body">
                            <p className="card-title">22 May 2023</p>
                            <p className="card-text">
                              Chalo Raises $45 Mn In Series D, Taking The Total
                              Funds Raised To $119 Mn
                            </p>
                            <a
                              href="https://chalo.com/news/fundraise2023"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://www.thehindubusinessline.com/companies/insurancedekho-raises-150-m-in-series-a-funding-from-goldman-sachs-tvs-capital-funds/article66504788.ece"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Media_Image/InsuranceDekho2_Cropped.jpg"
                            alt="Card cap"
                          />
                          <div className="card-body">
                            <p className="card-title">14 February 2023</p>
                            <p className="card-text">
                              InsuranceDekho raises $150 m in Series A Funding
                              from Goldman Sachs, TVS Capital funds
                            </p>
                            <a
                              href="https://www.thehindubusinessline.com/companies/insurancedekho-raises-150-m-in-series-a-funding-from-goldman-sachs-tvs-capital-funds/article66504788.ece"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://entrackr.com/2023/01/exclusive-insurancedekho-secures-its-maiden-external-investment/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Media_Image/insurancedekho_Cropped.jpg"
                            alt="Card cap"
                          />
                          <div className="card-body">
                            <p className="card-title">6 January 2023</p>
                            <p className="card-text">
                              Exclusive: InsuranceDekho raises Rs 300 Cr led by
                              Goldman Sachs
                            </p>
                            <a
                              href="https://entrackr.com/2023/01/exclusive-insurancedekho-secures-its-maiden-external-investment/"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://www.entrepreneur.com/en-in/news-and-trends/avataar-venture-partners-launch-fund-ii/437325"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/media_img.png"
                            alt="Card cap"
                          />
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">17 October 2022</p>
                            <p className="card-text">
                              Avataar Venture Partners Launches New Fund
                            </p>
                            <a
                              href="https://www.entrepreneur.com/en-in/news-and-trends/avataar-venture-partners-launch-fund-ii/437325"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://hr.economictimes.indiatimes.com/news/industry/sense-acquires-ai-recruitment-platform-skillate/94086948"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/sense_news_img.png"
                            alt="Card cap"
                          />
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">09 September 2022</p>
                            <p className="card-text">
                              Sense acquires AI recruitment platform Skillate
                            </p>
                            <a
                              href="https://hr.economictimes.indiatimes.com/news/industry/sense-acquires-ai-recruitment-platform-skillate/94086948"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <div className="col-12">
                      <a
                        href="https://www.thehindubusinessline.com/companies/media-saas-company-amagi-raises-95-million-funding-at-unicorn-valuation/article65231119.ece"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card custom-card-spec mb-2">
                          <img
                            className="card-img-top"
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/amagi_news_img.png"
                            alt="Card cap"
                          />
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">16 March 2022</p>
                            <p className="card-text">
                              Media SaaS company Amagi raises $95 million
                              funding at unicorn valuation
                            </p>
                            <a
                              href="https://www.thehindubusinessline.com/companies/media-saas-company-amagi-raises-95-million-funding-at-unicorn-valuation/article65231119.ece"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://www.forbes.com/sites/simranvaswani/2022/02/08/indias-elasticrun-becomes-unicorn-with-300-million-funding-round-led-by-softbank/?sh=44b020224813"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/avataar_media_img.png"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">Feb 8, 2022</p>
                            <p />
                            <p className="card-text">
                              India’s ElasticRun Becomes Unicorn With $300
                              Million Funding Round Led By SoftBank
                            </p>
                            <a
                              href="https://www.forbes.com/sites/simranvaswani/2022/02/08/indias-elasticrun-becomes-unicorn-with-300-million-funding-round-led-by-softbank/?sh=44b020224813"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://www.rapidtvnews.com/2021091261206/amagi-secures-100mn-cash-injection.amp.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Amagi_-_Momentum_PR_banner_8Oct2020+Cropped.jpeg"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">12 September 2021</p>
                            <p />
                            <p className="card-text">
                              Amagi secures $100MN cash injection
                            </p>
                            <a
                              href="https://www.rapidtvnews.com/2021091261206/amagi-secures-100mn-cash-injection.amp.html"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://yourstory.com/2021/06/funding-alert-sense-raises-16-m-series-c-funding-r/amp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/sensePartner4.png"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">17 June 2021</p>
                            <p />
                            <p className="card-text">
                              Talent management startup Sense raises $16M Series
                              C round led by Avataar Ventures
                            </p>
                            <a
                              href="https://yourstory.com/2021/06/funding-alert-sense-raises-16-m-series-c-funding-r/amp"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://www.zenoti.com/press/zenoti-raises-160-million-in-series-d-funding-round-led-by-advent-international-surpassing-1b-unicorn-valuation"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://letsventure.s3-ap-southeast-1.amazonaws.com/zenoti_news_croped_img.png"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title" />
                            <p />
                            <p className="card-text">
                              Zenoti Raises $160 Million in Series D Funding
                              Round Led by Advent International, Surpassing $1B
                              Unicorn Valuation
                            </p>
                            <a
                              href="https://www.zenoti.com/press/zenoti-raises-160-million-in-series-d-funding-round-led-by-advent-international-surpassing-1b-unicorn-valuation"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://economictimes.indiatimes.com/tech/funding/elasticrun-raises-75-million-led-by-existing-investors/articleshow/82296944.cms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/elastic_funding_raise_img.jpeg"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">29 Apr 2021, Bangalore</p>
                            <p className="card-text" style={{ height: '80px' }}>
                              ElasticRun raises $75 million led by existing
                              investors
                            </p>
                            <a
                              href="https://economictimes.indiatimes.com/tech/funding/elasticrun-raises-75-million-led-by-existing-investors/articleshow/82296944.cms"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://economictimes.indiatimes.com/industry/banking/finance/banking/crmnext-wins-the-2021-dream-company-to-work-for-award-presented-by-times-ascent-and-the-world-hrd-congress/articleshow/82003360.cms?utm_source=whatsapp_pwa&utm_medium=social&utm_campaign=socialsharebuttons&from=mdr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/crm_next_news_img.jpeg"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">10 Apr 2021, Bangalore</p>
                            <p className="card-text">
                              CRMNEXT Wins the 2021 "Dream Company to Work For"
                              Award Presented by Times Ascent and the World HRD
                              Congress
                            </p>
                            <a
                              href="https://economictimes.indiatimes.com/industry/banking/finance/banking/crmnext-wins-the-2021-dream-company-to-work-for-award-presented-by-times-ascent-and-the-world-hrd-congress/articleshow/82003360.cms?utm_source=whatsapp_pwa&utm_medium=social&utm_campaign=socialsharebuttons&from=mdr"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://timesofindia.indiatimes.com/business/india-business/b2b-focused-avataar-ventures-raises-100m-in-opportunities-fund/articleshow/81082273.cms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/focus_avataar_news_img.png"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">18 feb 2021, Bangalore</p>
                            <p className="card-text" style={{ height: '96px' }}>
                              B2B focused Avataar Ventures raises $100m in
                              Opportunities Fund
                            </p>
                            <a
                              href="https://timesofindia.indiatimes.com/business/india-business/b2b-focused-avataar-ventures-raises-100m-in-opportunities-fund/articleshow/81082273.cms"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <div
                        className="card mb-3 seperate_card_ap_border"
                        onClick={this.pressRouteHandler}
                      >
                        <div className="seperate-img-card">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Avataar-Press-release.jpg"
                            className="card-img-top img-fluid"
                            alt="..."
                          />
                        </div>
                        <div className="card-body seperate_card_body_ap">
                          <p className="card-title">21 Sep 2019, Bangalore</p>
                          <p className="card-text">
                            Avataar - backed by HarbourVest Partners to launch
                            new VC paradigm to help fund & globally scale
                            B2B/SaaS companies
                          </p>
                          <a
                            onClick={this.pressRouteHandler}
                            style={{ color: '#3b73b5' }}
                          >
                            More
                            <span>&nbsp;&#62;</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/avataar-venture-partners-to-invest-300m-in-b2b-and-saas-companies/articleshow/71251825.cms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/avataar-economictimes.jpg"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">21 Sep 2019, Bangalore</p>
                            <p className="card-text" style={{ height: '92px' }}>
                              Avataar Venture Partners to invest $300 million in
                              B2B and SaaS companies
                            </p>
                            <a
                              href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/avataar-venture-partners-to-invest-300m-in-b2b-and-saas-companies/articleshow/71251825.cms"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="item">
                    <div className="col-12">
                      <a
                        href="https://inc42.com/buzz/former-freshworks-norwest-partners-executives-launch-300-mn-investment-fund/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="card mb-3 seperate_card_ap_border">
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Avataar-INC-42.jpg"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">21 Sep 2019, Bangalore</p>
                            <p className="card-text" style={{ height: '92px' }}>
                              Former Freshworks, Norwest Partners Executives
                              Launch $300 Mn Investment Fund
                            </p>
                            <a
                              href="https://inc42.com/buzz/former-freshworks-norwest-partners-executives-launch-300-mn-investment-fund/"
                              target="_blank"
                            >
                              More
                              <span>&nbsp;&#62;</span>spurthiModal
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        {/******CONTACT SECTION*********/}
        <div className="contact_inverted_line" />

        <div className="row contact-us pt-3 pb-5" id="contact">
          <div
            className="col-sm-12 text-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="mt-5 text-center ethos-heading">
              <center>
                <p>CONTACT US</p>
              </center>
            </div>
            <div className="contact-wrapper mt-4">
              <div className="contact-item">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Location.svg"
                  className="mt-2"
                  alt="contact_item"
                />
                <div className="contact-us-title mt-3">BANGALORE OFFICE</div>
                <div className="contact-us-content mt-2">
                  67/1B, 4th Cross, <br />
                  Lavelle Rd, Bangalore- 560001
                </div>
              </div>

              <div className="contact-item mail_icon">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Mail.svg"
                  className="mt-2"
                  width="46"
                  height="46"
                  alt="contact_item"
                />

                <div className="contact-us-title mt-3">WRITE TO US</div>
                <div className="contact-us-content mt-2">
                  contact@avataar.vc
                </div>
              </div>
              <div className="contact-item">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Location.svg"
                  alt="contact_item"
                  className="mt-2"
                />
                <div className="contact-us-title mt-3">DELHI OFFICE</div>
                <div className="contact-us-content mt-2">
                  The Quorum Club <br />2 Horizon Center, First Floor, <br />
                  Golf Course Road, DLF Phase 5, <br />
                  Sector-43, Gurgaon, Haryana - 122002
                </div>
              </div>
            </div>
            <div style={{ width: '80%', margin: '5px  auto 0' }}>
              <p>
                Avataar Ventures is committed to ensure that our employees and
                external stakeholders can raise their concerns without
                retaliation or fear of not being heard. Any grievances or issues
                can be emailed anonymously to{' '}
                <a href="mailto:ethics@avataar.vc" style={{ color: '#3B73B5' }}>
                  {' '}
                  ethics@avataar.vc
                </a>
              </p>
            </div>
          </div>
        </div>

        {/******NISHANT MODAL********/}
        <div className="modal" id="nishantModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Nishant_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Nishant Rao</div>
                        <div className="stakeholder-position">
                          <b>
                            Investor | Proven Scale-up Entrepreneur | Sales &
                            GTM expert | Strategy & BizOps
                          </b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a href="" target="_blank">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Mail_modal.svg"
                            width="25"
                            height="25"
                          />
                        </a>
                        <a href="https://twitter.com/nishrao/" target="_blank">
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/twitter_logo.svg"
                            width="25"
                            height="25"
                            alt="avatar_twitter"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/nishantrao/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="avataar_linkedin"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Nishant Rao brings expertise in B2B technology & SaaS
                        sales and marketing with a repeat track record of
                        delivering value & globally scaling successful companies
                        in consumer internet & enterprise software with multiple
                        stints of growth past $100M+.
                      </p>
                      <p>
                        He has played many avataars across a multitude of
                        leadership roles from running global growth-stage SaaS
                        companies achieving unicorn status (Global Head of
                        Strategy, LinkedIn HQ then MD, LinkedIn-India & SE Asia
                        + Global COO @ Freshworks) to being an entrepreneur
                        (helped co-found Epicenter, India’s 1st voice
                        call-centre), a C-level strategy advisor (McKinsey) & an
                        engineer/product manager (Ariba & Microsoft).
                      </p>
                      <p>
                        He has an MBA from the Massachusetts Institute of
                        Technology and a Computer Science degree from UT,
                        Austin. He has been recognized as 40- under-40 executive
                        by the likes of Economic Times & Fortune for impact
                        created
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://capillarytech.com/" target="_blank">
                        Capillary
                      </a>{' '}
                      |{' '}
                      <a href="https://www.crmnext.com/" target="_blank">
                        CRMNext
                      </a>{' '}
                      |{' '}
                      <a href="https://www.zenoti.com/" target="_blank">
                        Zenoti
                      </a>{' '}
                      |{' '}
                      <a href="https://rategain.com/" target="_blank">
                        RateGain
                      </a>{' '}
                      |{' '}
                      <a href="https://www.sensehq.com/" target="_blank">
                        Sense
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END NISHANT MODAL*******/}

        {/**********MOHAN MODAL*********/}
        <div className="modal" id="mohanModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mohan_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Mohan Kumar</div>
                        <div className="stakeholder-position">
                          <b>Investor | Product Maven | Team Builder</b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://twitter.com/kumarmohanv"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/twitter_logo.svg"
                            width="25"
                            height="25"
                            alt="twitter_logo"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/vmohankumar"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Mohan Kumar was the Managing Partner at Norwest Venture
                        Partners (NVP) and has led investment rounds in some of
                        the most successful Enterprise and SAAS startup stories
                        in India.
                      </p>
                      <p>
                        Before his avataar as a VC, he spent two decades in
                        leadership & operating roles including as Corporate
                        Vice-President of Motorola, responsible for leading
                        their Mobile Products division & scaling their
                        product/design centres across Asia.
                      </p>
                      <p>
                        He is an IIT undergrad alum with Exec-MBA from Kellogg.
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://www.elastic.run/" target="_blank">
                        Elastic Run
                      </a>{' '}
                      |{' '}
                      <a href="https://algonomy.com/" target="_blank">
                        Algonomy
                      </a>{' '}
                      |{' '}
                      <a href="https://healsoftware.ai/" target="_blank">
                        Heal Software
                      </a>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**********END MOHAN MODAL**********/}

        {/*********MIKE MODAL***********/}
        <div className="modal" id="mikeModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Mike-mulica_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Mike Mulica</div>
                        <div className="stakeholder-position">
                          <b>Operating Partner</b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://www.linkedin.com/in/mikemulica/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Mike Mulica is an advisor for AVP, based out of Chicago,
                        US. A key strategist & farsighted investor in the areas
                        of mobile communications, the internet, and globally
                        scaled vertical solutions that Avataar has been able to
                        bank upon. Mike is a mobile and internet industry
                        veteran, playing a foundational role in pioneering the
                        Mobile Internet and has led many highly successful
                        companies and product introductions across the globe.
                      </p>
                      <p>
                        Mike spends his time as an active investor, board
                        director and advisor to private companies and public
                        companies. Mike’s stewardship & governance of companies
                        in emerging technologies has ushered in new large global
                        markets that have changed the way the world works.
                        Mike’s advisory companies include Phone.com, Syniverse,
                        Sierra Wireless, Openwave, RealNetworks, FusionOne,
                        Synchronoss, Actility, Unwired Planet Inc, Airmap,
                        AlefEdge and most recently Heal Software.
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://healsoftware.ai/" target="_blank">
                        Heal Software
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END MIKE MODAL*********/}

        {/*************GRORGE MODAL*********/}
        <div className="modal" id="georgeModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/George+_Cropped2.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">
                          George Thangadurai
                        </div>
                        <div className="stakeholder-position">
                          <b>
                            Operating Partner - Fund Management & Compliance
                          </b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://twitter.com/George_Thangad"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/twitter_logo.svg"
                            width="25"
                            height="25"
                            alt="twitter_logo"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/george-thangadurai-9034ba3/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        George drives business development functions for AVP
                        based out of San Francisco and helps portfolio companies
                        build deep CXO connects. He is a seasoned technology
                        executive with multi-functional leadership experience,
                        startup investments and helping grow businesses.
                      </p>
                      <p>
                        Earlier George spent more than two decades in Intel
                        Corporation in senior management roles that included GM
                        of strategy & product management enabling revenue growth
                        from $7B to $20B+, member of the Intel Capital
                        Investments Committee and GM of Services Incubation with
                        3 successful launches. He was awarded the prestigious
                        Intel’s Achievement Award for contributions to business
                        growth.
                      </p>
                      <p>
                        He leads global business for Borqs Technologies, a
                        leading IoT company, with a key role during its Nasdaq
                        IPO process and growing global revenues. George was also
                        CSO/CMO and Board Advisor for Mobiliya Technologies,
                        which was acquired by Quest Global. He has held multiple
                        board and advisory roles and is a trusted advisor to
                        many CxOs of emerging companies.
                      </p>
                      <p>
                        George has 6 USA patents, 2 IEEE publications and
                        interviewed in multiple technology magazines.
                      </p>
                    </div>
                    <div className="heading">Current Companies</div>

                    <div className="current_companies">
                      <a href="https://healsoftware.ai/" target="_blank">
                        Heal Software
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************END GEORGE MODAL******/}
        {/****************SUDHANSHU MODAL********/}
        <div className="modal" id="sudhanshuModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/George+_Cropped2.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Sudhanshu Bhasin</div>
                        <div className="stakeholder-position">
                          <b>
                            Business Analysis & Modeling Guru | BizOps |
                            Investment Banking
                          </b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://www.linkedin.com/in/sudhanshubhasin/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Sudhanshu brings in deep domain knowledge of the B2B
                        technology and SaaS space. As an ex-investment banker at
                        Lincoln International, he worked extensively with
                        multiple technology companies.
                      </p>
                      <p>
                        In his last role, Sudhanshu helped raise growth capital
                        for an HR-tech SaaS company and a global,
                        Enterprise-focused Logistics SaaS company. Prior to
                        that, he held investment banking roles at Nomura and
                        Edelweiss. Sudhanshu also has extensive capital markets
                        experience having executed several IPOs in his previous
                        roles.
                      </p>
                      <p>
                        Sudhanshu holds an MBA from NMIMS ( Mumbai) and a B.E.
                        from Delhi College of Engineering (Delhi University). He
                        is also CFA Level-III cleared.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***************END SUDHANSHU MODAL***********/}
        {/*********shobhit MODAL***********/}
        <div className="modal" id="shobhitModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Shobhit_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Shobhit Gupta</div>
                        <div className="stakeholder-position">
                          <b>Growth Platform Lead | GTM expert</b>
                        </div>
                      </div>
                      <div className="stakeholder-social-links">
                        <a
                          href="https://www.linkedin.com/in/shobhitgupta23/"
                          target="_blank"
                        >
                          <img
                            src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Linkedin.svg"
                            width="25"
                            height="25"
                            alt="linkedin_logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Shobhit leads the growth platform team of Avataar and
                        partners with the management teams of the portfolio
                        companies on growth topics. He brings a mix of
                        management consulting (McKinsey, PwC), SaaS start-up,
                        and large tech industry (Dell) experience. He
                        specializes in go-to-market planning and execution
                        including identifying attractive market segments with
                        clear right to win and formulating sales pathways to
                        effectively reach them. Collaborating with and learning
                        from inspiring entrepreneurs and building on India's
                        distinctive advantage to build tech products for global
                        markets excites him.
                      </p>
                      <p>
                        He holds a Master of Science from Texas A&M University.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END shobhit MODAL*********/}
        {/*********spurthi MODAL***********/}
        <div className="modal" id="spurthiModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Spurthi_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Spurthi Naidu </div>
                        <div className="stakeholder-position">
                          <b>People & Operation Manager</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Spurthi is part of our team as a Operations associate
                        where she actively engages with the portfolio companies
                        in their growth alongside the fund venture activities.
                        Prior to this she was at Let’s Accelerate (Let’s
                        Venture) and worked with the team in designing &
                        managing startup innovation programs. Her early career
                        started with Kalaari Capital Advisors as a HR executive
                        who led Kstart's first Fellowship program.
                      </p>
                      <p>
                        Spurthi is a Gold Medalist from Bangalore University in
                        her MBA - HR dept.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END spurthi MODAL*********/}

        <div className="modal" id="ganeshModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Ganesh_Nayak_Avataar+-78_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Ganesh Nayak </div>
                        <div className="stakeholder-position">
                          <b>Fundraising and Capital Markets</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Ganesh drives Fundraising & Capital Markets at Avataar.
                        He comes with deep understanding of the venture
                        ecosystem, and relationship with India's top
                        entrepreneurs, VCs, and global institutional investors.
                      </p>
                      <p>
                        Ganesh has been a founding member of LetsVenture -
                        India’s premier private market platform with over 750
                        direct startup investments. Some of his marquee deals
                        include PolicyBazaar, Mobikwik, MCaffeine among others.
                        He has also raised capital for global VC funds like
                        Pioneer Fund, MFV Partners, IndiaQuotient among others.
                        He has served on the LP Advisory Board at Pioneer Fund
                        (top decile VC).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="subhroModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Subhro_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Subhro Sengupta </div>
                        <div className="stakeholder-position">
                          <b>B2B Ecommerce | Business Strategy | Investments</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Subhro comes with experience in B2B businesses, having
                        worked with the founding team at B2B unicorn udaan.
                      </p>
                      <p>
                        At udaan, he was a part of the Corporate Development
                        team, working on strategy for new businesses and
                        managing strategic investments and M&A. His expertise
                        lies in B2B marketplaces, SME credit, SME SaaS,
                        logistics and all things targeted towards fragmented B2B
                        markets in India.
                      </p>
                      <p>
                        Prior to udaan, Subhro was a part of the early stage
                        investment team at Kalaari Capital in Bengaluru, where
                        he looked at startups in B2B Ecommerce, Content,
                        Healthtech and Fintech.
                      </p>
                      <p>
                        Subhro is a National Law University alumnus, with a
                        degree in Law and Economics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="krishModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Krishen_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Krish Kupathil </div>
                        <div className="stakeholder-position">
                          <b>Venture Partner</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        A serial entrepreneur and a veteran angel investor,
                        Krish has joined AVP as an advisor based out of US.
                        Krish brings in more than 25 years of experience in
                        building and growing companies and new markets. As an
                        advisor, Krish will be available to help Avataar and its
                        portfolio companies realize transformative solutions by
                        connecting technology with real-world business
                        challenges and opportunities.
                      </p>
                      <p>
                        Drawing from his passion of building, investing and
                        incubating start-ups, Krish has previously been
                        responsible for executing a host of start-ups, business
                        turnarounds, implementation of high-growth models,
                        capital raising and successful exits. He has been
                        focused on businesses looking to leverage innovative
                        products, solutions and business models and creatively
                        use technology to commercialize novel ideas.
                      </p>
                      <p>
                        His insights and an innovative take on commercialization
                        of digital technologies like IoT, AI, AR will help bring
                        value to AVP’s portfolio.
                      </p>
                      <p>
                        Apart from AVP, Krish has formerly been associated with
                        diverse companies like global enterprise mobility
                        company Mobiliya (now acquired by QuEST Global), ABI
                        Health and ImaginXP as a CEO, investor &amp; mentor,
                        respectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*********Diwakar MODAL***********/}
        <div className="modal" id="DiwakarModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Diwakar_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Diwakar Anand </div>
                        <div className="stakeholder-position">
                          <b>Senior Associate</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Diwakar comes with experience in investing across both
                        private and public markets, having worked with Eight
                        Roads and Goldman Sachs.
                      </p>
                      <p>
                        At Eight Roads, he was part of the Tech investments team
                        – driving investments across Enterprise tech, Fintech,
                        B2B Tech and Consumer segments. He believes SaaS will
                        continue to have strong growth momentum, with product /
                        community led growth strategies leading the way.
                      </p>
                      <p>
                        Prior to Eight Roads, Diwakar worked as an equity
                        analyst at Goldman Sachs and as a consultant at McKinsey
                        & Company. At McKinsey, he advised PE firms and
                        conglomerates on their strategy and investments.
                      </p>
                      <p>
                        Diwakar completed his Masters in Finance from Jamnalal
                        Bajaj Institute of Management studies, University of
                        Mumbai and BFIA from Shaheed Sukhdev College of Business
                        Studies, Delhi University.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Diwakar MODAL*********/}

        {/*********Akshita MODAL***********/}
        <div className="modal" id="AkshitaModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Akshita_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Akshita Shah</div>
                        <div className="stakeholder-position">
                          <b>Associate</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Akshita Shah started her career in the IPO team of L&T
                        infotech where she managed compliance and investor
                        relations. She later joined Acuris as a M&A analyst.
                        Here she tracked the North American and European markets
                        for value creation opportunities and published sectoral
                        reports. Post this, Akshita joined Peak Ventures as an
                        investment analyst. She looked after EdTech, AgriTech
                        and Electric Mobility. She also briefly interned in the
                        strategy team of Dehaat as a Pre-MBA analyst.
                      </p>
                      <p>
                        Akshita holds a BAF from KJ Somaiya and an MBA from the
                        Indian School of Business. She is a CS and a CFA.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********Deepak MODAL***********/}
        <div className="modal" id="DeepakModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Deepak+Rathi_image_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">DEEPAK RATHI</div>
                        <div className="stakeholder-position">
                          <b>Analyst</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Deepak joined the firm in 2023, and currently serves as
                        Analyst in the Investment and BizOps team, assisting in
                        the investment due diligence process and operational
                        value-add initiatives. Prior to joining the firm, Deepak
                        was an equity research analyst at Spark Capital in their
                        institutional equities desk focusing on Cement and Real
                        estate sector. Prior to Spark Capital, Deepak worked
                        with VT Capital (hedge fund) as a senior-analyst, where
                        he tracked Healthcare space and developed quant-based
                        trading strategies. Deepak holds an MBA from the Indian
                        School of Business. He is also a qualified Chartered
                        Accountant and holds a CFA.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Deepak MODAL*********/}
        {/*********END Akshita MODAL*********/}
        {/*********Dinky MODAL***********/}
        <div className="modal" id="DinkyModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Dinky_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">DINKY SANGHVI</div>
                        <div className="stakeholder-position">
                          <b>VP Finance</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Dinky Sanghvi has experience of complete life cycle of
                        PE/VC Fund i.e., Fund raise / closings, Capital call,
                        Disbursement to portfolio companies, exit, distribution
                        to investors and winding-down of Funds At Avataar Dinky
                        is setting up & driving Fund Management functions &
                        building internal Finance foundations as an
                        entrepreneurial journey and will play a key part in
                        helping us build a new format global VC platform.
                      </p>
                      <p>
                        She comes with 15+ years of experience at ICICI Venture
                        Fund Management Co. Ltd. As Vice President Finance, she
                        used to manage Private Equity Funds and Asset Management
                        Company in Finance & operating roles.
                      </p>
                      <p>
                        She is a qualified Chartered Accountant and Company
                        Secretary.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Dinky MODAL*********/}
        {/*********Amit MODAL***********/}
        <div className="modal" id="AmitModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Amit_Kumar_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Amit Kumar</div>
                        <div className="stakeholder-position">
                          <b>Head - Finance COE</b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Amit joined the firm in 2022 and currently serves as the
                        Finance COE Lead overseeing the financial and
                        operational process, Standardization of Financial
                        operations, Strategy towards Pre IPO and IPO readiness
                        and Profitability initiatives for the Portfolio
                        companies of Avataar.
                      </p>
                      <p>
                        Prior to joining the firm, Amit was the founding member
                        and Director of LOGIQORE. The firm provided niche CFO
                        Services to early and growth stage SaaS and B2B
                        start-ups in India and ASEAN region helping the
                        companies to re-model their Go-To-Market strategy,
                        streamline their finance operations, cash flow
                        management, raise funds from investors and improve their
                        profitability. Before LOGIQORE, Amit was the CFO of
                        Attune Technologies which is a SaaS Healthcare product
                        company [a Norwest portfolio company]. He has also
                        worked in Leadership positions with multinationals like
                        GE, Oracle, Schweppes and Bosch in India and
                        International region.
                      </p>
                      <p>
                        Amit is a CA and holds an Honors degree in Finance from
                        Magadh University. He also holds certification in
                        leadership and Merger and acquisition from Crotonville.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Amit MODAL*********/}
        {/*********Anirudh MODAL***********/}
        <div className="modal" id="AnirudhModal">
          <div className="modal-dialog" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="inverted_line_modal" />

              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="stakeholder">
                  <div className="card stakeholder-img">
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Profile/Anirudh_Cropped.jpg"
                      className="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div className="stakeholder-main">
                    <div className="stakeholder-head">
                      <div className="stakeholder-head-left">
                        <div className="stakeholder-name">Anirudh Singh</div>
                        <div className="stakeholder-position">
                          <b>
                            Investor | M&A And Fundraising | Business Strategy |
                            Team Builder
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="stakeholder-content">
                      <p>
                        Anirudh Singh has been an investor in the Indian
                        technology ecosystem for the last ~15 years. Through his
                        investing career across marquee funds such as Norwest
                        Venture Partners, Alpha Wave Global, Canaan Partners,
                        and B Capital, he has led investment rounds in some of
                        the most successful B2B companies stories in India
                        across food delivery, agri-tech, ed-tech, financial
                        services, marketplaces, gaming etc., across early stage
                        to public markets.
                      </p>
                      <p>
                        His most recent role was as Managing Director at Alpha
                        Wave Global and Head of AWG’s venture growth fund, Alpha
                        Wave Incubation. At AWG, Anirudh was responsible for
                        running the India, UAE, and South East Asia offices of
                        the fund. Prior to joining Alpha Wave Global, Anirudh
                        was an investor with B Capital, a $5b technology
                        investment fund, focused on growth equity investment
                        opportunities in the technology space in India and South
                        East Asia. Prior to that, Anirudh spent 7 years at
                        Norwest Venture Partners, a $9b technology investment
                        fund out of Silicon Valley, covering early stage to
                        public market investments in India across technology,
                        consumer retail, and financial services. Anirudh started
                        his investing career at Canaan Partners, a $10b
                        technology investment fund out of Silicon Valley, where
                        he spent time evaluating early stage investment
                        opportunities in the technology sector in India.
                      </p>
                      <p>
                        Anirudh graduated as an electrical engineer from Thapar
                        University, Patiala, India and completed his MBA from
                        the Indian Institute of Management, Ahmedabad, India
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*********END Anirudh MODAL*********/}
      </Fragment>
    );
  }
}

export default withRouter(Home);
