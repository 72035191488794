import React, { Component, lazy } from "react";
import "./App.css";
import { Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Ethos from "./components/Ethos/Ethos";
import Portfolio from "./components/Portfolio/Portfolio";
import Team from "./components/Team/Team";
import Media from "./components/Media/Media";
import Contact from "./components/Contact/Contact";
import Press from "./components/PressRelease/Press";
// import loadable from "@loadable/component";

// const Home = loadable(() => import("./components/Home/Home"), {
// 	fallback: <React.Fragment>Home</React.Fragment>
//   });

class App extends Component {
	// constructor(props){
	// 	super(props)
	// 	this.state = {
	// 		ready : false
	// 	}
	// }
	// componentDidMount() {
	// 	this.setState({
	// 		ready  :true
	// 	})
	// }
  render() {
    return (
      <div >
        <BrowserRouter>
          <ScrollToTop>
            <Navbar />
            <Route path="/" exact component={Home} />
            <Route path="/ethos" exact component={Ethos} />
            <Route path="/portfolio" exact component={Portfolio} />
            <Route path="/team" exact component={Team} />
            <Route path="/media" exact component={Media} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/press" exact component={Press} />
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;