import React, { Component, Fragment } from 'react';
import './media.css';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        loop: false,
        margin: 10,
        nav: false,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      },
    };
  }

  // componentWillMount() {
  // 	$(document).ready(function () {
  // 		$("#seperate_media_section").owlCarousel({
  // 			itemsDesktop: [1499, 3],
  // 			itemsDesktopSmall: [1199, 3],
  // 			itemsTablet: [899, 2],
  // 			itemsMobile: [599, 1],
  // 			navigation: true,
  // 			navigationText: [],
  // 			autoWidth: true,
  // 			// items: 3,
  // 		});
  // 	});
  // }
  pressRouteHandler = () => {
    // localStorage.setItem("press_route", true);
    // window.location.assign("/press");
    this.props.history.push('/press');
  };

  render() {
    return (
      <Fragment>
        <div className="media_hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero_text">
                  <h1 className="media_banner_heading">MEDIA</h1>
                  <p>
                    Our Avataar reflected in how we are seen and what we want to
                    say.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="ethos"
          className="ethos"
          data-aos="fade-up"
          data-aos-duration="100"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ethos-framework-para">
                  <p>NEWS</p>
                </div>
              </div>
            </div>
          </div>

          <section className="media_section_content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <OwlCarousel className="owl-theme" {...this.state.options}>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://www.financialexpress.com/healthcare/healthplix-this-startup-helps-doctors-digitise-their-practice-heres-how/3131034/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Media_Image/healthpixteam_img.jpg"
                              alt="Card cap"
                            />
                            <div className="card-body">
                              <p className="card-title">19 June 2023</p>
                              <p className="card-text">
                                HEALTHPLIX: This startup helps doctors digitise
                                their practice – Here’s how
                              </p>
                              <a
                                href="https://www.financialexpress.com/healthcare/healthplix-this-startup-helps-doctors-digitise-their-practice-heres-how/3131034/"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://chalo.com/news/fundraise2023"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/fundraise.png"
                              alt="Card cap"
                            />
                            <div className="card-body">
                              <p className="card-title">22 May 2023</p>
                              <p className="card-text">
                                Chalo Raises $45 Mn In Series D, Taking The
                                Total Funds Raised To $119 Mn
                              </p>
                              <a
                                href="https://chalo.com/news/fundraise2023"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://www.thehindubusinessline.com/companies/insurancedekho-raises-150-m-in-series-a-funding-from-goldman-sachs-tvs-capital-funds/article66504788.ece"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Media_Image/InsuranceDekho2_Cropped.jpg"
                              alt="Card cap"
                            />
                            <div className="card-body">
                              <p className="card-title">14 February 2023</p>
                              <p className="card-text">
                                InsuranceDekho raises $150 m in Series A Funding
                                from Goldman Sachs, TVS Capital funds
                              </p>
                              <a
                                href="https://www.thehindubusinessline.com/companies/insurancedekho-raises-150-m-in-series-a-funding-from-goldman-sachs-tvs-capital-funds/article66504788.ece"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://entrackr.com/2023/01/exclusive-insurancedekho-secures-its-maiden-external-investment/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Media_Image/insurancedekho_Cropped.jpg"
                              alt="Card cap"
                            />
                            <div className="card-body">
                              <p className="card-title">6 January 2023</p>
                              <p className="card-text">
                                Exclusive: InsuranceDekho raises Rs 300 Cr led
                                by Goldman Sachs
                              </p>
                              <a
                                href="https://entrackr.com/2023/01/exclusive-insurancedekho-secures-its-maiden-external-investment/"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://www.entrepreneur.com/en-in/news-and-trends/avataar-venture-partners-launch-fund-ii/437325"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/media_img.png"
                              alt="Card cap"
                            />
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">17 October 2022</p>
                              <p className="card-text">
                                Avataar Venture Partners Launches New Fund
                              </p>
                              <a
                                href="https://www.entrepreneur.com/en-in/news-and-trends/avataar-venture-partners-launch-fund-ii/437325"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://hr.economictimes.indiatimes.com/news/industry/sense-acquires-ai-recruitment-platform-skillate/94086948"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/sense_news_img.png"
                              alt="Card cap"
                            />
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">09 September 2022</p>
                              <p className="card-text">
                                Sense acquires AI recruitment platform Skillate
                              </p>
                              <a
                                href="https://hr.economictimes.indiatimes.com/news/industry/sense-acquires-ai-recruitment-platform-skillate/94086948"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="col-12">
                        <a
                          href="https://www.thehindubusinessline.com/companies/media-saas-company-amagi-raises-95-million-funding-at-unicorn-valuation/article65231119.ece"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card custom-card-spec mb-2">
                            <img
                              className="card-img-top"
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/amagi_news_img.png"
                              alt="Card cap"
                            />
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">16 March 2022</p>
                              <p className="card-text">
                                Media SaaS company Amagi raises $95 million
                                funding at unicorn valuation
                              </p>
                              <a
                                href="https://www.thehindubusinessline.com/companies/media-saas-company-amagi-raises-95-million-funding-at-unicorn-valuation/article65231119.ece"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://www.forbes.com/sites/simranvaswani/2022/02/08/indias-elasticrun-becomes-unicorn-with-300-million-funding-round-led-by-softbank/?sh=44b020224813"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/avataar_media_img.png"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">Feb 8, 2022</p>
                              <p />
                              <p className="card-text">
                                India’s ElasticRun Becomes Unicorn With $300
                                Million Funding Round Led By SoftBank
                              </p>
                              <a
                                href="https://www.forbes.com/sites/simranvaswani/2022/02/08/indias-elasticrun-becomes-unicorn-with-300-million-funding-round-led-by-softbank/?sh=44b020224813"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://www.rapidtvnews.com/2021091261206/amagi-secures-100mn-cash-injection.amp.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/Amagi_-_Momentum_PR_banner_8Oct2020+Cropped.jpeg"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">12 September 2021</p>
                              <p />
                              <p className="card-text">
                                Amagi secures $100MN cash injection
                              </p>
                              <a
                                href="https://www.rapidtvnews.com/2021091261206/amagi-secures-100mn-cash-injection.amp.html"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://yourstory.com/2021/06/funding-alert-sense-raises-16-m-series-c-funding-r/amp"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/sensePartner4.png"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">17 June 2021</p>
                              <p />
                              <p className="card-text">
                                Talent management startup Sense raises $16M
                                Series C round led by Avataar Ventures
                              </p>
                              <a
                                href="https://yourstory.com/2021/06/funding-alert-sense-raises-16-m-series-c-funding-r/amp"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://www.zenoti.com/press/zenoti-raises-160-million-in-series-d-funding-round-led-by-advent-international-surpassing-1b-unicorn-valuation"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://letsventure.s3-ap-southeast-1.amazonaws.com/zenoti_news_croped_img.png"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title" />
                              <p />
                              <p className="card-text">
                                Zenoti Raises $160 Million in Series D Funding
                                Round Led by Advent International, Surpassing
                                $1B Unicorn Valuation
                              </p>
                              <a
                                href="https://www.zenoti.com/press/zenoti-raises-160-million-in-series-d-funding-round-led-by-advent-international-surpassing-1b-unicorn-valuation"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://economictimes.indiatimes.com/tech/funding/elasticrun-raises-75-million-led-by-existing-investors/articleshow/82296944.cms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/elastic_funding_raise_img.jpeg"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">
                                29 Apr 2021, Bangalore
                              </p>
                              <p
                                className="card-text"
                                style={{ height: '80px' }}
                              >
                                ElasticRun raises $75 million led by existing
                                investors
                              </p>
                              <a
                                href="https://economictimes.indiatimes.com/tech/funding/elasticrun-raises-75-million-led-by-existing-investors/articleshow/82296944.cms"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://economictimes.indiatimes.com/industry/banking/finance/banking/crmnext-wins-the-2021-dream-company-to-work-for-award-presented-by-times-ascent-and-the-world-hrd-congress/articleshow/82003360.cms?utm_source=whatsapp_pwa&utm_medium=social&utm_campaign=socialsharebuttons&from=mdr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/crm_next_news_img.jpeg"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">
                                10 Apr 2021, Bangalore
                              </p>
                              <p className="card-text">
                                CRMNEXT Wins the 2021 "Dream Company to Work
                                For" Award Presented by Times Ascent and the
                                World HRD Congress
                              </p>
                              <a
                                href="https://economictimes.indiatimes.com/industry/banking/finance/banking/crmnext-wins-the-2021-dream-company-to-work-for-award-presented-by-times-ascent-and-the-world-hrd-congress/articleshow/82003360.cms?utm_source=whatsapp_pwa&utm_medium=social&utm_campaign=socialsharebuttons&from=mdr"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://timesofindia.indiatimes.com/business/india-business/b2b-focused-avataar-ventures-raises-100m-in-opportunities-fund/articleshow/81082273.cms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/focus_avataar_news_img.png"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">
                                18 feb 2021, Bangalore
                              </p>
                              <p
                                className="card-text"
                                style={{ height: '96px' }}
                              >
                                B2B focused Avataar Ventures raises $100m in
                                Opportunities Fund
                              </p>
                              <a
                                href="https://timesofindia.indiatimes.com/business/india-business/b2b-focused-avataar-ventures-raises-100m-in-opportunities-fund/articleshow/81082273.cms"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <div
                          className="card mb-3 seperate_card_ap_border"
                          onClick={this.pressRouteHandler}
                        >
                          <div className="seperate-img-card">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Avataar-Press-release.jpg"
                              className="card-img-top img-fluid"
                              alt="..."
                            />
                          </div>
                          <div className="card-body seperate_card_body_ap">
                            <p className="card-title">21 Sep 2019, Bangalore</p>
                            <p className="card-text">
                              Avataar - backed by HarbourVest Partners to launch
                              new VC paradigm to help fund & globally scale
                              B2B/SaaS companies
                            </p>
                            <a
                              onClick={this.pressRouteHandler}
                              style={{ color: '#3b73b5' }}
                            >
                              More
                              <span>&nbsp;&#62;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/avataar-venture-partners-to-invest-300m-in-b2b-and-saas-companies/articleshow/71251825.cms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/avataar-economictimes.jpg"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">
                                21 Sep 2019, Bangalore
                              </p>
                              <p
                                className="card-text"
                                style={{ height: '92px' }}
                              >
                                Avataar Venture Partners to invest $300 million
                                in B2B and SaaS companies
                              </p>
                              <a
                                href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/avataar-venture-partners-to-invest-300m-in-b2b-and-saas-companies/articleshow/71251825.cms"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="item">
                      <div className="col-12">
                        <a
                          href="https://inc42.com/buzz/former-freshworks-norwest-partners-executives-launch-300-mn-investment-fund/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card mb-3 seperate_card_ap_border">
                            <div className="seperate-img-card">
                              <img
                                src="https://s3.ap-south-1.amazonaws.com/avataar.vc/assets/Avataar-INC-42.jpg"
                                className="card-img-top img-fluid"
                                alt="..."
                              />
                            </div>
                            <div className="card-body seperate_card_body_ap">
                              <p className="card-title">
                                21 Sep 2019, Bangalore
                              </p>
                              <p
                                className="card-text"
                                style={{ height: '92px' }}
                              >
                                Former Freshworks, Norwest Partners Executives
                                Launch $300 Mn Investment Fund
                              </p>
                              <a
                                href="https://inc42.com/buzz/former-freshworks-norwest-partners-executives-launch-300-mn-investment-fund/"
                                target="_blank"
                              >
                                More
                                <span>&nbsp;&#62;</span>
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </OwlCarousel>

                  {/*<div
										id="media_section"
										className="owl-carousel mt-5"
									>
									
										<div className="col-12">
											<div className="card mb-3 seperate_card_ap_border">
												<div className="seperate-img-card">
													<img
														src={require("../assets/Avataar-Press-release.jpg")}
														className="card-img-top img-fluid"
														alt="..."
													/>
												</div>
												<div className="card-body seperate_card_body_ap">
													<p className="card-title">
														21 Sep 2019, Bangalore
													</p>
													<p className="card-text">
														Avataar - backed by
														HarbourVest Partners to
														launch new VC paradigm
														to help fund & globally
														scale B2B/SaaS companies
													</p>
													<a href="#">
														More
														<span>&nbsp;&#62;</span>
													</a>
												</div>
											</div>
										</div>

																				<div className="col-12">
											<div className="card mb-3 seperate_card_ap_border">
												<div className="seperate-img-card">
													<img
														src={require("../assets/avataar-economictimes.jpg")}
														className="card-img-top img-fluid"
														alt="..."
													/>
												</div>
												<div className="card-body seperate_card_body_ap">
													<p className="card-title">
														21 Sep 2019, Bangalore
													</p>
													<p className="card-text">
														Avataar Venture Partners
														to invest $300 million
														in B2B and SaaS
														companies
													</p>
													<a href="#">
														More
														<span>&nbsp;&#62;</span>
													</a>
												</div>
											</div>
										</div>

										
										<div className="col-12">
											<div className="card mb-3 seperate_card_ap_border">
												<div className="seperate-img-card">
													<img
														src={require("../assets/Avataar-INC-42.jpg")}
														className="card-img-top img-fluid"
														alt="..."
													/>
												</div>
												<div className="card-body seperate_card_body_ap">
													<p className="card-title">
														21 Sep 2019, Bangalore
													</p>
													<p className="card-text">
														Former Freshworks,
														Norwest Partners
														Executives Launch $300
														Mn Investment Fund
													</p>
													<a href="#">
														More
														<span>&nbsp;&#62;</span>
													</a>
												</div>
											</div>
										</div>
									</div>*/}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

export default Media;
